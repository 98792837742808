import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-session-timed-out',
  templateUrl: './session-timed-out.component.html',
  styleUrls: ['./session-timed-out.component.scss']
})
export class SessionTimedOutComponent implements OnInit {

  refreshSession = false;
  logOutSession = false
  userSessionID: any;

  constructor(
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public session: any,
    public dialogRef: MatDialogRef<SessionTimedOutComponent>
  ) { }

  ngOnInit(): void {
    console.log("dialog data => ", this.session);

    if(this.session.refreshUser === true){
      this.refreshSession = true;
    }
    if(this.session.expiredUser === true){
      this.refreshSession = false;
      this.logOutSession = true;
    }
 }

  logOut(){
    console.log("ereasing local storages...");
    let sessionKey: any = false;
    localStorage.setItem('userLogged', sessionKey);
    this.auth.logout();
    this.dialogRef.close('logOUT!');
  }

  refreshUserSession(){
    this.auth.refreshSessionId(this.session.sID).subscribe(res => {
      console.log('user session refreshed => ', res);
      //this.dialog.closeAll();
      this.dialogRef.close('refreshUserSession');
    })
  }

  // addMinutes(date, minutes){
  //   return new Date(date.getTime() + minutes*60000)
  // }
}
