<h1 mat-dialog-title>Template Editor</h1>
<div mat-dialog-content>


  <form [formGroup]="myForm" (keydown.enter)="$event.preventDefault()">


    <mat-form-field class="full-width">
      <input matInput [formControl]="myForm.controls['templateViewName']" placeholder="Template Name"
        [disabled]="!isDefault">
    </mat-form-field>
    <br><br>
    <section>
       <mat-checkbox [formControl]="myForm.controls['publicView']"  >Share View?</mat-checkbox>
    </section>
    <br><br>
    <section>
      <mat-checkbox [formControl]="myForm.controls['globalView']">Make Global</mat-checkbox>
    </section>
  </form>
</div>

<div mat-dialog-actions>


  <button mat-flat-button color="primary"
    (click)="btnSave()">Save</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <button mat-button color="warn" type="button" (click)="btnDelete()">Delete</button>
</div>