<!-- <h2 mat-dialog-title>{{this.data?.title}}</h2> -->
<h4 mat-dialog-title>
    <button style="float:right" mat-icon-button class="closer" [mat-dialog-close]>
        <mat-icon mat-list-icon>close</mat-icon>
    </button>

    <span>Row History</span>
    <span class="fill-space"></span>
    <span flex></span>
</h4>
<mat-dialog-content>

    <div *ngFor="let r of results" class="tbl">
        <fieldset>
            <legend>{{r.columnName}}</legend>
        <div  >
            <div>

                <b>New Value: </b><span>{{r.newValue}}</span>
            </div>
            <div>
                <b>Previous Value: </b><span>{{r.previousValue}}</span>
            </div>
            <div>
                <div>
                    <b>Updated Date: </b><span>{{r.updatedDate | date:'short'}}</span>
                </div>
                <div>
                    <b>by User: </b><span>{{r.userName}}</span>
                </div>
            </div>
        </div>
        </fieldset>
    </div>
    

    <div *ngIf="results?.length==0">
        <h4>There has been no change to this value</h4>
    </div>
</mat-dialog-content>
