export const environment = {
    production: true,
  //  serverURL: 'https://pinnacleproduction.azurewebsites.net',
    serverURL: 'https://pdf.apidas.com',
  //  siteReviewURL: 'https://api.apidas.com/',     
    siteReviewURL: 'https://pinnacle.apidas.com',     
    adobePDFkey:'058ae776ee354cec8f289d990a9d1dd9',
    trashFolder: '18OJv1-wWz8Dw6ll_0c1dlthdEnEY3JFO',
    authenticationExpirationTimeoutMinutes: 36000  //(10 hours - per tyler request)
  };
  