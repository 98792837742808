export class ChatRecord {
  to: string;
  cc?: string[];
  message: string;
  from: string;
  sid: string;
  time: Date;
  constructor(to: string, cc: string[] = [], message: string = '', from: string = '', sid: string = '') {
    this.cc = cc;
    this.to = to;
    this.message = message;
    this.from = from;
    this.sid = sid;
    this.time = new Date()
  }
}


export class JoggingRecord {
  id: number;
  distance: number;
  description: string;
  createdAt: string;
}

export class Contact {
  name: string;
  department: string;
}

export class ChatMessage {
  name: string;
  contact: string;
  message: string;
  time: string;
}

