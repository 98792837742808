import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from "../../../services/navigation/navigation.service";
import { Router } from '@angular/router';
import { DashboardComponent } from 'app/views/dashboard/dashboard.component';
import { AuthService } from 'app/services/auth/auth.service';
import { environment } from 'environments/environment';
import { releaseInfo } from 'app/release-info.config';

import * as moment from 'moment/moment';
import { interval, timer } from 'rxjs';
import { DataStoreService } from 'app/services/datastore.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { SessionTimedOutComponent } from '../session-timed-out/session-timed-out.component';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.template.html',
 
})
export class NavigationComponent {
  @ViewChild('sessionTimeout') sessionTimeout: ElementRef;

  hasIconTypeMenuItem;
  iconTypeMenuTitle: string;
  menuItems: any[];
  session: any;
  env: any;
  relInfo:any;
  apiServerStatus: any = { "DataSource": "loading", "InitialCatalog": "loading", "DBStatus": "UNKNOWN" };
  dataServerStatus: any = { "Server": "loading", "Database": "loading", "DBStatus": "UNKNOWN" };
  idleInterval: any;
  sessionTimeDisplay: number;
  sessionData: any;

  intervalSubscription
  expireTime;
  user;
  logOutNextDayInterval: any;

  constructor(
    private navService: NavigationService,
    private router: Router,
    private auth: AuthService,
    private ds: DataStoreService,
    private dialog: MatDialog,
    
  ) { }



  async ngOnInit() {
    this.env = environment;
    this.relInfo = releaseInfo;
    this.session = this.auth.session;
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    // Loads menu items from NavigationService
    this.navService.menuItems$.subscribe(async menuItem => {
      this.menuItems = menuItem;
      
       
      console.log('menu',this.menuItems);
       
      this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;
    });

    this.auth.getServerStatus(this.env.siteReviewURL)
      .subscribe((retval: any) => {
        this.dataServerStatus = retval;
        this.auth.database = retval.Database;
        //this.subscribeToServerTimeOut()
      });
    this.auth.getServerStatus(this.env.serverURL)
      .subscribe(retval => {
        this.apiServerStatus = retval;

      });

    this.checkSessionIdle();
    this.startLogOutNextDayInterval();

    //update the server timeout indicator  
  }

  filterMenu(groups: string[], groupName: string, menuName: string) {
    let devs = groups.filter(g => g === groupName);
    if (devs.length == 0) {
      this.menuItems = this.menuItems.filter(obj => obj.name != menuName)
    }
  }

  subscribeToServerTimeOut() {
    var sessionMinutesRemaining = '';
    var expiresAt;
    if (!this.expireTime) {
      expiresAt = JSON.parse(localStorage.getItem(this.auth.expiresAtKey) || '{}');
      this.expireTime = expiresAt;

    } else {
      expiresAt = this.expireTime;
    }
    var diffTime = (new Date(expiresAt).getTime()) - (new Date().getTime());
    var duration = moment.duration(diffTime, 'milliseconds');
    var expTime = moment(new Date(expiresAt)).weekday() + ", " + moment(new Date(expiresAt)).hour() + ":" + moment(new Date(expiresAt)).minute()
    var timeRemaining = (duration.hours() + " hours, " + duration.minutes() + " minutes")
    this.sessionTimeout.nativeElement.innerHTML = 'Session Expires:' + expTime;

    //setInterval(() => this.subscribeToServerTimeOut(), 1000 * 60)
    this.intervalSubscription = interval(700).subscribe(() => {
      this.sessionTimeout.nativeElement.innerHTML = 'Expires :' + expTime

    });
  }

  gotToDash() {
    // this.router.navigate(['reviews/dash']);
    this.router.navigate(['dashboard']);
  }


  checkSessionIdle() {
    let sID = localStorage.getItem('sr_session_id');
    //this.getSession(sID);
    //this.sessionIdle(sID);
    this.serverSideExpires(sID);
    this.idleInterval = interval(60000).subscribe(() => {
      //this.getSession(sID);
      //this.sessionIdle(sID);
      this.serverSideExpires(sID);
    })
  }

  getSession(sID) {
    this.auth.getSession(sID).subscribe((res: any) => {
      console.log("SESSION DATA => ", res);
      let newDateInMilliseconds = new Date().getTime();
      let sessionDate = new Date(res.SessionDate).getTime();
      //console.log("SESSION Date => ", sessionDate, newDateInMilliseconds, res.SessionTimeoutMinutes);


      // Nacho test local 4hs more to millisecconds
      //let sessionTimeoutMinutesToMills = 242 * 60000;

      // Dan local test?
      //let sessionTimeoutMinutesToMills = 2 * 60000;

      let sessionTimeoutMinutesToMills = res.SessionTimeoutMinutes * 60000;
      let sessionTime = sessionDate + sessionTimeoutMinutesToMills;
      this.sessionTimeDisplay = Math.round((sessionTime - newDateInMilliseconds) / 60000);
      //console.log("sessionTimeDisplay => ", this.sessionTimeDisplay)
      if (newDateInMilliseconds > sessionTime) {
        //alert('logOUT user!')
        this.idleInterval.unsubscribe();
        this.auth.logout();
      } else {
        return;
      }
    });
  }

  sessionIdle(sID) {
    this.auth.getSession(sID).subscribe((res: any) => {
      console.log("SESSION DATA => ", res);
      this.sessionData = res;
      //check if session Date is 1/1/1900
      let expiredDate = new Date(res.SessionDate).toLocaleDateString();

      if (expiredDate === "1/1/1900") {
        this.openSessionTimedOut();
      } else {

        let newDateInMilliseconds = new Date().getTime();
        let sessionDate = new Date(res.SessionDate).getTime();

        //242 is for Nacho test 2 mins
        let sessionTimeoutMinutesToMills = 242 * 60000;

        //let sessionTimeoutMinutesToMills = res.ExpiresInMinutes * 60000;
        //let sessionTimeoutMinutesToMills = res.SessionTimeoutMinutes * 60000;
        //let sessionTimeoutMinutesToMills = 60 * 60000;

        let sessionTime = sessionDate + sessionTimeoutMinutesToMills;
        this.sessionTimeDisplay = Math.round((sessionTime - newDateInMilliseconds) / 60000);
        //console.log("sessionTimeDisplay => ", this.sessionTimeDisplay)
        let sessionKey;
        if (newDateInMilliseconds > sessionTime) {
          console.log('logOUT user!')
          this.idleInterval.unsubscribe();
          this.openSessionTimedOut();
          sessionKey = false;
          localStorage.setItem('userLogged', sessionKey);
        } else {
          sessionKey = true;
          localStorage.setItem('userLogged', sessionKey);
        }
      }
    }, err => {
      console.log('error getting session Data, login again => ', err);
      this.idleInterval.unsubscribe();
      this.auth.logout();
    });
  }

  serverSideExpires(sID) {
    //console.log("Serverside expire data");

    this.auth.getSession(sID).subscribe((res: any) => {
      this.sessionData = res;
      this.sessionData = { ...this.sessionData, sID: sID };

      let expiredDate = new Date(res.SessionDate).toLocaleDateString();
      let expiresInMinutes = res.ExpiresInMinutes;
      //let expiresInMinutes = 0;
      //let expiresInMinutes = -1 ;
      //let expiresInMinutes = 10;

      console.log("SESSION DATA => ", this.sessionData, "expiresInMinutes => ", expiresInMinutes);
      this.sessionTimeDisplay = expiresInMinutes;
      if (expiredDate === "1/1/1900" || expiresInMinutes <= 1) {
        this.dialog.closeAll();
        this.sessionData = { ...this.sessionData, expiredUser: true };
        this.openSessionTimedOut();
        this.idleInterval.unsubscribe();
      }

      if (expiresInMinutes === 10) {
        this.dialog.closeAll();
        this.sessionData = { ...this.sessionData, refreshUser: true };
        this.openSessionTimedOut();
        //this.idleInterval.unsubscribe();
      }

      //this.openSessionTimedOut();

    }, err => {
      console.log('error getting session Data, login again => ', err);
      this.idleInterval.unsubscribe();
      this.auth.logout();
    })
  }

  openSessionTimedOut() {
    console.log("sessionTimeOut pop upsessionData => ", this.sessionData);
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = this.sessionData;
    config.width = "500px";
    config.height = "350px";
    config.disableClose = true;
    const dialogRef = this.dialog.open(SessionTimedOutComponent, config);
    dialogRef.afterClosed().subscribe(res => {
      console.log('Dialog res => ', res);
      if (res === "refreshUserSession") {
        this.idleInterval.unsubscribe();
        this.checkSessionIdle();
      }
    })
  }

  startLogOutNextDayInterval() {
    console.log('startLogOutNextDayInterval');
    this.logOutNextDay();
    this.logOutNextDayInterval = interval(15000).subscribe(() => {
      this.logOutNextDay();
    });
  }

  logOutNextDay() {
    const lastLoginString = localStorage.getItem('lastLogin');
    if (lastLoginString) {
      const lastLogin = new Date(lastLoginString);
      const today = new Date();
      //console.log('checking logOutNextDay... lastlogin => ', lastLogin, ' today => ', today);
      if (today.getDate() !== lastLogin.getDate() || today.getMonth() !== lastLogin.getMonth() || today.getFullYear() !== lastLogin.getFullYear()) {
        // If last login is not today, perform logout
        this.auth.logout();
        this.logOutNextDayInterval.unsubscribe();
      } else {
        //console.log('we are in the same day, not login out')
      }
    }
  }

}