<!-- DIALOG FORM -->

<form name="myForm" novalidate (ngSubmit)="validate()" autocomplete="off">

    <div style="width: 100%;" *ngIf="validating != true">
        <h4 mat-dialog-title>
            <mat-toolbar role="toolbar">
                <button style="float:right" mat-icon-button class="closer" [mat-dialog-close]="myForm.value">
                    <mat-icon mat-list-icon>close</mat-icon>
                </button>
                <span>New Job</span>
                <span class="fill-space"></span>
                <span flex></span>


            </mat-toolbar>
        </h4>

        <md-dialog-content>

            <div fxLayout="row wrap" fxLayoutGap="30px">
                 <div class="form-field" style="height: 60px;">
                    <mat-form-field class="full-width">
                        <mat-select (selectionChange)="onCustomerSelect($event)" name="customerID"
                            placeholder="Customer" [formControl]="myForm.controls['customerID']">
                            <mat-option *ngFor="let r of customers" [value]="r.customerID">
                                {{r.customerName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>  
                </div>
                 <div class="form-field" style="height: 60px">
                     <mat-form-field class="full-width">
                        <mat-select placeholder="Work Type" [formControl]="myForm.controls['workDescription']">
                            <mat-option *ngFor="let r of workDescriptions" [value]="r">
                                {{r}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>  
                </div>
                <div class="form-field" style="height: 60px;">
                    <mat-form-field class="full-width">
                        <mat-label>Job Scope</mat-label>
                        <mat-select placeholder="Job Scope" [formControl]="myForm.controls['jobScope']">
                            <mat-option *ngFor="let workType of workTypeOptions" [value]="workType.name">
                                {{workType?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                 <div class="form-field-small">
                    <mat-form-field class="full-width"> 
                        <input matInput [formControl]="myForm.controls['jobNumber']" type="number"
                             placeholder="PM Number ">
                    </mat-form-field>
                </div>
                 <div class="form-field-small">
                    <mat-icon matTooltip="Search CCM Master for Matching Record" *ngIf="!searchingCCM" style="float: right;
                                    cursor:pointer;
                                    margin-top: 20px;
                                    margin-bottom: -90px;
                                    margin-right: -25;" (click)="onSelectWorkType($event)">search</mat-icon>
                  
                  <mat-icon matTooltip="Searching..." *ngIf="searchingCCM" style="float: right;
                                    cursor:pointer;
                                    margin-top: 20px;
                                    margin-bottom: -90px;
                                    margin-right: -25;" (click)="onSelectWorkType($event)">hourglass_empty</mat-icon>

 


                    <mat-form-field  style="float:left" > 
                        <input  matInput [formControl]="myForm.controls['notificationNumber']" type="number"
                             placeholder="Notification Number ">
                     
                    </mat-form-field>
                </div>
                 
                 <div class="form-field">
                    <mat-form-field class="full-width"> 
                        <input  matInput [formControl]="myForm.controls['address1']"  placeholder="Address" Googleplace
                            (setAddress)="getAddressOnChange($event,LocationCtrl)">
                    </mat-form-field>
                </div>
                 <div class="form-field">
                    <mat-form-field class="full-width"> 
                        <input  matInput [formControl]="myForm.controls['city']"  placeholder="City">
                    </mat-form-field>
                </div>
                
                <div class="form-field-small">
                    <mat-form-field class="full-width"> 
                        <input currencyMask matInput [formControl]="myForm.controls['bidPrice']"  placeholder="WA Amount">
                    </mat-form-field>
                </div>
                 <div class="form-field-small">
                    <mat-form-field class="full-width"> 
                        <input  matInput [formControl]="myForm.controls['MATCode']"  placeholder="MAT Code">
                    </mat-form-field>
                </div>
            </div>


        </md-dialog-content>

        <div mat-dialog-actions align="end">
            <!-- <button mat-flat-button [mat-dialog-close]="myForm.value">Cancel</button> -->
            <button mat-flat-button color="primary" [disabled]="!myForm.valid" type="submit">Create Job</button>&nbsp;
        </div>

    </div>
</form>
<div style="width:100%;margin-top:100px;text-align: center;" *ngIf="validating && !jobCreated">
    <mat-spinner style="margin-left:auto;margin-right:auto" [diameter]="130">
    </mat-spinner>
    <br><br>
    <h3>Preparing new folders in Google Drive</h3>
</div>

<div class="jobCreated" *ngIf="jobCreated">
    <h1>Your job was created successfully</h1>
    <div class="dialogOptions">
        <h3>Do you want to?</h3>
        <button *ngIf="data != null" mat-button (click)="redirectTo()">{{data.redirectMessage}}</button>
        <button mat-button (click)="goToBid()">Go to bid editor</button>
    </div>
</div>