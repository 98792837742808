import { Injectable } from '@angular/core';
import { SideBarDef } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class AgGridHelperService {

  defaultColDef = {
                    editable: false,
                    flex: 1,
                    minWidth: 100,
                    enableValue: true,
                    enableRowGroup: true,
                    enablePivot: true,
                    sortable: true,
                    filter: "agSetColumnFilter",
                    resizable: true,
                  }

  defaultSideBarDef: SideBarDef = { toolPanels: ['columns', 'filters'] }

  defaultStatusBar = {
                        statusPanels: [
                          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'right' },
                          { statusPanel: "agFilteredRowCountComponent" },
                          { statusPanel: "agSelectedRowCountComponent" },
                          { statusPanel: "agAggregationComponent" }
                        ]
                      }


  constructor() { }


  newGridOptions(){
    let  gridOptions: any = {
      statusBar: this.defaultStatusBar,
      pivotMode: false,
      sideBar:this.defaultSideBarDef,
      toolPanelSuppressSideButtons: true,
      suppressPaginationPanel: true, 
      columnDefs:[],
      defaultColDef: this.defaultColDef
    }
    return gridOptions;
  }


  dateComparator(date1, date2) {



    var date1Number = Date.parse(date1);
    var date2Number = Date.parse(date2);


    // these values are here to make sure that empty fields are at the bottom.  
    // sometimes we use 1/1/1900 as a non-null space holder, but in the UI it is not displayed
    var defaultHiddenDate = Date.parse('1900-01-01 00:00:00.000');
    var superHighDateValue = Date.parse('1/1/2199');

    if (isNaN(date1Number) || date1Number === null || date1Number === defaultHiddenDate) { date1Number = superHighDateValue; }
    if (isNaN(date2Number) || date2Number === null || date2Number === defaultHiddenDate) { date2Number = superHighDateValue; }



    return date1Number - date2Number;

  }
  shortDate(params) {
    let cellValue = params.value;
    let dateToNumber = Date.parse(cellValue);
    if (isNaN(dateToNumber)) {
      return '';
    } else {
      let yr = new Date(cellValue).getFullYear();
      if (yr < 2000) return '';
      let shortDate = new Date(cellValue).toLocaleDateString("en-US");
      return shortDate;
    }
  }
  
  dateFilterComparator(filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return 0;

    var cellDate: Date;
    var dateNumber = Date.parse(dateAsString);
    if (isNaN(dateNumber)) {
      cellDate = new Date(1, 1, 2000);
    } else {
      cellDate = new Date(cellValue);
    }
    // Now  we can compare
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  }
 // Converts a number to currency
 currencyFormatter(params) {
  var money;
  if (params.value) {
    let myval = parseFloat(params.value);
    money = myval.toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"); //somehow this adds a comma in the correct place in the number format.

  } else {
    money = params.value;
  }
  let retval = '$' + money;
  return retval;
}



}
