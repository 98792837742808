
import { Component, Inject, OnInit } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GoogleAddressParser } from 'app/helpers/google-address-parser';
import { SiteReviewService } from 'app/services/site-review.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataStoreService } from 'app/services/datastore.service';
import { Command } from 'app/models/command';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-new-job',
  templateUrl: './new-job.component.html',
  styleUrls: ['./new-job.component.scss']
})
export class NewJobComponent implements OnInit {
  myForm: FormGroup;
  customers = [{ customerID: null, customerName: "...loading" }]
  workDescriptions = [{ workDescription: "...loading" }]
  validating = false;
  jobCreated = false;
  obj = { jobNumber: "", notificationNumber: "", customerID: 0, jobName: "", address1: "", city: "", workDescription: "", state: "", postCode: "", latitude: "", longitude: "", bidPrice: "", MATCode: '', jobScope: '' }
  newJobObj: any;
  settedJobFolders: boolean;
  jobCreationStep: number;
  jobCreationError: string;
  workTypeOptions = [];

  constructor(private fb: FormBuilder,
    private siteReviewService: SiteReviewService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewJobComponent>,
    private dataStore: DataStoreService) {
    dialogRef.disableClose = true;

    console.log('opened with ', this.data)
    if (this.data.job) {
      this.obj.jobNumber = this.data.job.jobNumber;
      this.obj.notificationNumber = this.data.job.notificationNumber;
      this.obj.address1 = this.data.job.address1;
      this.obj.city = this.data.job.city;
      this.obj.customerID = this.data.job.customerID;
      this.obj.workDescription = this.data.job.workDescription;
      this.obj.state = this.data.job.state;
      this.obj.postCode = this.data.job.postCode;
      this.obj.bidPrice = this.data.job.bidPrice;
      this.obj.MATCode = this.data.job.MATCode;
    }

    this.buildForm(this.obj);
  }


  async ngOnInit() {
    this.getEventTypeOptions();
    this.workDescriptions = [];
    var jobTypes = [];
    jobTypes = await this.dataStore.getWorkType();
    jobTypes.forEach(jt => this.workDescriptions.push(jt.description))
    this.workDescriptions = this.workDescriptions.sort();

    //    this.workDescriptions =  await this.siteReviewService.getWorkDescriptionOptions()
    console.log('got worktypes', this.workDescriptions)
    this.customers = await this.siteReviewService.getCustomersAsync()
    console.log('got customers', this.customers)
    this.buildForm(this.obj)

  }

  buildForm(obj) {

    this.myForm = this.fb.group({
      jobNumber: new FormControl(obj.jobNumber || '', [Validators.required]),
      workDescription: new FormControl(obj.workDescription || null, [Validators.required]),
      notificationNumber: new FormControl(obj.notificationNumber || '', [Validators.required]),
      customerID: new FormControl(obj.customerID || 0, [Validators.required]),
      bidPrice: new FormControl(obj.bidPrice || 0, ),
      MATCode: new FormControl(obj.MATCode || '', [Validators.required]),
      address1: new FormControl(obj.address1 || '', [Validators.required]),
      city: new FormControl(obj.city || '', [Validators.required]),
      state: new FormControl(obj.state || 'CA', [Validators.required]),
      postCode: new FormControl(obj.postCode || '', ),
      latitude: new FormControl(obj.latitude || '', ),
      longitude: new FormControl(obj.longitude || '', ),
      jobScope: new FormControl(obj.jobScope || '', [Validators.required])
    })
  }
  onCustomerSelect(e) { }
  searchingCCM = false;
  
  async onSelectWorkType(e) {
    this.searchingCCM = true;
    var nn = this.myForm.controls["notificationNumber"].value;
    var jn = this.myForm.controls["jobNumber"].value;

    if (nn && jn) {

      var hasRec: any = await this.siteReviewService.lookupCCMMaster(jn, nn)
      if (hasRec) {
        console.log('has ccm record', hasRec);

        var street = hasRec.street;
        var city = hasRec.city;
        this.myForm.controls["city"].setValue(city);
        this.myForm.controls["address1"].setValue(street);
        this.myForm.controls["state"].setValue('CA');


      } else {
        console.log('no ccm record');
      }

    }
    this.searchingCCM = false;

  }


  getEventTypeOptions() {
    let cmd = new Command();
    cmd.procedure = 'cmdGetEventTypes';
    this.siteReviewService.command(cmd)
      .pipe(
        map(res => { return res })
      )
      .subscribe(retval => {
        retval.forEach(workType => {
           console.log('cmdGetEventTypes ' + workType);
          if(workType.name !='Equipment'){
            this.workTypeOptions.push(workType);
          }
         
        });
      });
  }


  // async validate() {
  //   this.obj = this.myForm.value;
  //   this.obj.jobName = this.obj.address1;
  //   this.obj.state = 'CA';

  //   let basics = {
  //     jobNumber: this.obj.jobNumber, notificiationNumber: this.obj.notificationNumber,
  //     customerID: this.obj.customerID
  //   }
  //   this.validating = true;
  //   var checkDupes = await this.siteReviewService.checkJobDuplication(basics)
  //   var newJob;
  //   if (checkDupes.length > 0) {
  //     alert("there's a duplicate in the system!");
  //     this.validating = false;
  //     return false;
  //   } else {
  //     newJob = await this.siteReviewService.newJob(this.obj).catch(err=>{
  //       alert('oh snap!  where was a problem making this job.' );
  //       console.log(err)
  //     })
  //     // .catch(err=>{
  //     //   alert('oh snap!  where was a problem making this job.  Tech support has been notified!')
  //     //   this.slacker("error making new job "+ this.sessionId,JSON.stringify(obj))
  //     // })

  //   }


  //   this.validating = false;
  //   console.log('navigating to new job',newJob);
  //   this.router.navigateByUrl(`/reviews/jobs/${newJob.jobID}`)
  //   this.dialogRef.close();

  // }


  async validate() {
    this.obj = this.myForm.value;

    console.log("createJob obj => ", this.obj)

    //let formatBidPrice = this.obj.bidPrice.replace('$', '').replace('.', '');

    this.obj.jobName = this.obj.address1;
    this.obj.state = 'CA';
    //this.obj.bidPrice = formatBidPrice;

    let basics = {
      jobNumber: this.obj.jobNumber, 
      notificiationNumber: this.obj.notificationNumber,
      workDescription: this.obj.workDescription,
      customerID: this.obj.customerID
    }
    this.validating = true;
    var checkDupes = await this.siteReviewService.checkJobDuplication(basics);
    if (checkDupes.length > 0) {     
      if(!confirm("there's a duplicate in the system! Do you want to make the job anyway?")) {
        this.validating = false;
        return false;
      }
    }  
      // newJob = await this.siteReviewService.newJob(this.obj)
      //   .catch(err => {
      //     alert('oh snap!  where was a problem making this job.');
      //     console.log(err)
      //   })

     this.siteReviewService.createJobRecord(this.obj,{rfp:null,jobPackage:null})
     .subscribe(res => {
        let newJob = res[0];
        console.log("createJobRecord res => ", newJob);
        let jobID = newJob.jobID;
        this.setJobFolders(jobID);
        this.newJobObj = newJob;
        this.jobCreated = true;
      }, error => {
        alert('oh snap!  where was a problem making this job.');
        console.log(error)
      })
   
  }

  setJobFolders(jobID){
    this.settedJobFolders = false;
    console.log("setJobFolders with jobID => ", jobID);
    this.siteReviewService.setJobFolders(jobID).subscribe(res => {
      console.log("setJobFolders res => ", res);
      this.settedJobFolders = true;
    }, error => {
      console.warn("Error ", error.message);
      this.jobCreationError = "Something wrong happen creating the Job Folders";
    })
  }

  redirectTo() {
    this.router.navigateByUrl(this.data.redirectPage);
    this.jobCreated = false;
    this.dialogRef.close();
  }

  goToBid() {
    this.router.navigateByUrl(`/reviews/jobs/${this.newJobObj.jobID}`)
    this.jobCreated = false;
    this.dialogRef.close();
  }

  getAddressOnChange(item, locationCtrl) {
    console.log('getAddress event', item);
    console.log('get address control', locationCtrl);
    const address = new GoogleAddressParser(item.address_components).result();

    let f = this.myForm.controls;
    f["address1"].setValue(address.street_number + ' ' + address.street_name);
    f["city"].setValue(address.city);
    f["state"].setValue(address.state);
    f["postCode"].setValue(address.postal_code);

    if (item.geometry) {
      f["latitude"].setValue(item.geometry.location.lat);
      f["longitude"].setValue(item.geometry.location.lng);
    }


    console.log('address', address)


  }


}
