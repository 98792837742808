export const releaseInfo =
 {
 	branch: "beta",
 	lastCommit: "06/17/2024 10:34:31",
 	lastTag: "v3.1.4",
 	buildType: "beta",
 	company: "Pinnacle Power Services",
 	user: "dmorr",
 	compileTime: "06/20/2024 17:14:02"
 }
