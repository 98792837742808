import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: 'app-button-renderer',
  template: `<span (click)="onClick($event)" [innerHTML]="html | sanitizeHtml"></span> `
})

export class ButtonRendererComponent implements ICellRendererAngularComp {

  params;
  html: any;

 
  agInit(params): void {
    this.params = params;
    if(this.params.command){
      let c = this.params.command;
      //console.log('buttonRenderer',params)
      switch(c){
        case 'insert_delete':
          let id=params.data[params.id];
          if(id===0){
            this.html='<span class="material-icons" >save</span>'
          }else{
            this.html='<span class="material-icons" >delete</span>'
          }
          
          return;
      }
    }
    this.html = this.params.html || null;
   }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);

    }
  }
}