import { HttpClient } from '@angular/common/http';

import { Injectable, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { environment } from "../../environments/environment";

import { SmartSheetMap } from './smart-sheet-map';



@Injectable()
export class SmartSheetService {
    private sessionId;
    private apiServer = environment.siteReviewURL;
    
    private sessionIdKey = 'sr_session_id';
    private ssm: SmartSheetMap;
    sheets = { tags: "4947081661048708" }
    sheetMap = { sheet:"NB CCM Tags", columns: { ccmJobNumber:"8126397995607940",notificationNumber:"3023272875976580",pmNumber: "7526872503347076" }}
    constructor(private http: HttpClient) {
        this.sessionId = localStorage.getItem(this.sessionIdKey);
        this.ssm = new SmartSheetMap();
        // this.apiServer = environment.serverURL;

    }

    fieldUpdate(jobId: number, column: any, value: any, displayValue: any = '-EMPTY-') {
        console.log("no longer updatind smartsheets");
        return true;
        // console.log("updating smart sheets")
        // let smartSheetColumn = this.ssm.getMap(column);

        // if (smartSheetColumn != '') {
        //     console.log(column+"  is mapped to "+smartSheetColumn)
        //     if (displayValue==='-EMPTY-'){displayValue = value;}
        //     let fieldSet = { "RowID": 0, "JobID": jobId, "ColumnName": smartSheetColumn, "Value": value, "DisplayValue":displayValue }
        //     console.log("Here's the fieldset",fieldSet);

        //     let url: string = this.apiServer + '/api/FieldUpdate?sid=' + this.sessionId;
        //     this.http.post(url, fieldSet)
        //         .map((response: Response) => <any>response)
        //         .subscribe(retval => {
        //             console.log("Updated " + smartSheetColumn, retval);
        //         });
        //     //  .do(x => console.log(x));
        // }else{
        //     console.log("no smart sheet column mapped to "+column);
        // }
    }

    getRow(id): Observable<any> {

        let url: string = this.apiServer + '/api/SmartSheet/' + id + '?sid=' + this.sessionId;
        return this.http.get(url).pipe(
            map((response: Response) => <any>response));
    }

    getRowByJobNumber(jobNumber: number, notificationNumber: number, sheet: string = 'tags') {
        if(sheet=='tags'){ sheet = this.sheets.tags}
        let url: string = this.apiServer + '/api/SmartSheets/' + sheet + '/lookup?pm=' + jobNumber + '&nn=' + notificationNumber + '&sid=' + this.sessionId;
        return this.http.get(url).pipe(
            map((response: Response) => <any>response));
    }
 


    updateCCMTagFromSmartSheets(jobNumber:number, notificationNumber:number){
        let sheet= this.sheets.tags;
       
        let url: string = this.apiServer + '/api/SmartSheets/' + sheet + '/lookup?pm=' + jobNumber + '&nn=' + notificationNumber + '&sid=' + this.sessionId;

        return this.http.get(url)
        .pipe(map((res: any) => res));
      
      }

    saveBid(smartSheetRowID, JobID): Observable<any> {
        var obj = {
            "SmartSheetRowID": smartSheetRowID,
            "RowCount": 1,
            "JobID": JobID
        }
        let url: string = this.apiServer + '/api/SmartSheet/?sid=' + this.sessionId;
        return this.http.post(url, obj)



    }
    updateNodeSet(node: any, data: any) {

        this.updateNode(node, 'aPSRequired', data.APS_Required);
        this.updateNode(node, 'bidAdditionalCost', data.Bid_Additional_Costs);
        this.updateNode(node, 'bidAwardDate', data.Bid_Award_Date);
        this.updateNode(node, 'bidBaseElectric', data.Bid_Base_Electric);
        this.updateNode(node, 'bidBundleName', data.Bid_Bundle_Name);
        this.updateNode(node, 'bidDaysSinceRequest', data.Bid_Days_Since_Request);
        this.updateNode(node, 'bidDaysUntilDue', data.Bid_Days_Until_Due);
        this.updateNode(node, 'bidDueDate', data.Bid_Due_Date);
        this.updateNode(node, 'bidGDriveFolder', data.Bid_G_Dive_Folder);
        this.updateNode(node, 'bidOwnerID', data.Bid_Owner);
        this.updateNode(node, 'bidPrice', data.Bid_Price);
        this.updateNode(node, 'bidPThrs', data.Bid_PT_Hrs);
        this.updateNode(node, 'bidRequestDate', data.Bid_Request_Date);
        this.updateNode(node, 'bidRestoration', data.Bid_Restoration);
        this.updateNode(node, 'bidSThrs', data.Bid_ST_Hrs);
        this.updateNode(node, 'bidStatus', data.Bid_Status);
        this.updateNode(node, 'bidSubmittedDate', data.Bid_Submitted_Date);
        this.updateNode(node, 'bidTotalDaysToBid', data.Bid_Total_Days_to_Bid);
        this.updateNode(node, 'bidTrafficControl', data.Bid_Traffic_Control);
        this.updateNode(node, 'NONE', data.CCSC_Create_Job);
        this.updateNode(node, 'NONE', data.COP_Submitted);
        this.updateNode(node, 'changeOrders', data.Change_Orders);
        this.updateNode(node, 'cityNotification', data.City_Notification);
        this.updateNode(node, 'comments', data.Comments);
        this.updateNode(node, 'commitDate', data.Commit_date);
        this.updateNode(node, 'contractValue', data.Contract_Value);
        this.updateNode(node, 'cOPSubmitted', data.NONE);
        this.updateNode(node, 'costRestoration', data.Cost_Restoration);
        this.updateNode(node, 'costTrafficControl', data.Cost_Traffic_Control);
        this.updateNode(node, 'crewForeman', data.Crew_Foreman);
        this.updateNode(node, 'customer', data.Customer);
        this.updateNode(node, 'customerContact', data.Customer_Contact);
        this.updateNode(node, 'customerContactPhone', data.Customer_Contact_Phone);
        this.updateNode(node, 'differenceRestoration', data.Difference_Restoration);
        this.updateNode(node, 'differenceTrafficControl', data.Difference_Traffic_Control);
        this.updateNode(node, 'docsElectricalPermit', data.Docs_Electrical_Permit);
        this.updateNode(node, 'docsEncroachmentPermit', data.Docs_Encroachment_Permit);
        this.updateNode(node, 'docsForm2', data.Docs_Form_2);
        this.updateNode(node, 'docsForm48', data.Docs_Form_48);
        this.updateNode(node, 'docsTrafficControlPlans', data.Docs_Traffic_Control_Plans);
        this.updateNode(node, 'endDateActual', data.End_Date_Actual);
        this.updateNode(node, 'endDateForecast', data.End_Date_Forecast);
        this.updateNode(node, 'NONE', data.G_Calendar_Create_New_Event);
        this.updateNode(node, 'gMaps', data.G_Maps);
        this.updateNode(node, 'NONE', data.General_Foreman);
        this.updateNode(node, 'NONE', data.ID);
        this.updateNode(node, 'NONE', data.Inspection_Group);
        this.updateNode(node, 'invoiceDate', data.Invoice_Date);
        this.updateNode(node, 'invoiceNumber', data.Invoice_Number);
        this.updateNode(node, 'NONE', data.JP_Intent);
        this.updateNode(node, 'jobAddress', data.Job_Address);
        this.updateNode(node, 'jobCity', data.Job_City);
        this.updateNode(node, 'jobGDriveFolder', data.Job_G_Drive_Folder);
        this.updateNode(node, 'jobName', data.Job_Name);
        this.updateNode(node, 'jobNumber', data.Job_Number);
        this.updateNode(node, 'jobNumberPenta', data.Job_Number_Penta);
        this.updateNode(node, 'jobPackageHardCopyComplete', data.Job_Package_Hard_Copy_Complete);
        this.updateNode(node, 'jobPercentComplete', data.Job_Percent_Complete);
        this.updateNode(node, 'jobState', data.Job_State);
        this.updateNode(node, 'mATCode', data.MAT_Code);
        this.updateNode(node, 'metric1', data.Mertic2);
        this.updateNode(node, 'NONE', data.Metric1);
        this.updateNode(node, 'NONE', data.Metric3);
        this.updateNode(node, 'NONE', data.Metric4);
        this.updateNode(node, 'NONE', data.ModifiedAt);
        this.updateNode(node, 'NONE', data.ModifiedBy);
        this.updateNode(node, 'nodeGC', data.Node_GC);
        this.updateNode(node, 'pentaDate', data.PENTA_date);
        this.updateNode(node, 'pGEFieldEngineer', data.PGE_Field_Engineer);
        this.updateNode(node, 'pGERegion', data.PGE_Region);
        this.updateNode(node, 'pMNumber', data.PM_Number);
        this.updateNode(node, 'pOWRO', data.PO_WRO);
        this.updateNode(node, 'pinnacleDepartment', data.Pinnacle_Department);
        this.updateNode(node, 'primaryCustomer', data.Primary_Customer);
        this.updateNode(node, 'quoteID', data.Quote_ID);
        this.updateNode(node, 'NONE', data.RowNumber);
        this.updateNode(node, 'siteID', data.Site_ID);
        this.updateNode(node, 'startDateActual', data.Start_Date_Actual);
        this.updateNode(node, 'startDateForecast', data.Start_Date_Forecast);
        this.updateNode(node, 'totalInvoiced', data.Total_Invoiced);
        this.updateNode(node, 'trafficControl', data.Traffic_Control);
        this.updateNode(node, 'workDescription', data.Work_Description);




    }

    private updateNode(node, key: string, value: any) {
        try {
            node.setDataValue(key, value);
            return 1;
        } catch {
            return 0;
        }
    }
}
