import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './components/common/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/common/layouts/auth-layout/auth-layout.component';

import { AuthService } from './services/auth/auth.service';
import { NgModule } from '@angular/core';



export const rootRouterConfig: Routes = [
  { 
    path: '', 
    redirectTo: 'reviews/jobs', 
    pathMatch: 'full' 
  },
  {
    path: '', 
    component: AuthLayoutComponent,
    children: [
      { 
        path: 'sessions', 
        loadChildren: './views/sessions/sessions.module#SessionsModule',
        data: { title: 'Session'} 
      }
    ]
  },
  {
    path: '', 
    component: AdminLayoutComponent,
    //canActivate: [AuthService],
    children: [
      {
        path: 'dashboard', 
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
        data: { title: 'Dashboard', breadcrumb: 'Dashboard'}
      },    
      // {
      //   path: 'ops', 
      //   loadChildren: './views/operations/operations.module#OperationsModule', 
      //   data: { title: 'Operations', breadcrumb: 'Operations'}
      // },
      // {
      //   path: 'clients',  
      //   loadChildren: './views/client-center/client-center.module#ClientCenterModule', 
      //   data: { title: 'Client Center', breadcrumb: 'Client Center'}
      // },
      {
        path: 'customers',  
        loadChildren: './views/customer/customer.module#CustomerModule', 
        data: { title: 'Customers', breadcrumb: 'Customers' }
      },
      {
        path: 'reviews',  
        loadChildren:  () => import('./views/site-review/site-review.module').then(m => m.SiteReviewModule) , 
        data: { title: 'Reviews', breadcrumb: 'Reviews'}
        
      },
      {
        path: 'admin',  
        loadChildren: './views/admin/admin.module#AdminModule', 
        data: { title: 'Administration', breadcrumb: 'Administration'}
      },
      {
        path: 'finance',  
        loadChildren: './views/finance/finance.module#FinanceModule', 
        data: { title: 'Finance', breadcrumb: 'Finance'}
      },
      {
        path: 'crews', 
        loadChildren: './views/crews/crews.module#CrewsModule',
        data: { title: 'Crew Locator', breadcrumb: 'Crew Locator'}
      },
      {
        path: 'fleet', 
        loadChildren: './views/equipment/equipment.module#EquipmentModule',
        data: { title: 'Fleet', breadcrumb: 'Fleet'}
      },
      {
        path: 'support', 
        loadChildren: './views/equipment/equipment.module#EquipmentModule',
        data: { title: 'Fleet', breadcrumb: 'Fleet'}
      },
    ]
  },
  { 
    path: '**', 
    redirectTo: 'sessions/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(rootRouterConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule { }