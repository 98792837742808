import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router'; 
import { HubConnection } from '@microsoft/signalr';
import { ChatterService } from 'app/chat/shared/services/chatter.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;

  private _hubConnection: HubConnection | undefined;
  public async: any;
  message = '';
  messages: string[] = [];
  msgs: any;
  public name: string = '';
  sessionID=new Date().toString();


  // Dummy notifications
  notifications = [{
    message: 'New contact added',
    icon: 'assignment_ind',
    time: '1 min ago',
    route: '/inbox',
    color: 'primary'
  }, {
    message: 'New message',
    icon: 'chat',
    time: '4 min ago',
    route: '/chat',
    color: 'accent'
  }, {
    message: 'Server rebooted',
    icon: 'settings_backup_restore',
    time: '12 min ago',
    route: '/charts',
    color: 'warn'
  }]

  constructor(private router: Router, private chatter:ChatterService) {
    console.log('initiatiating the notification bar')
    this.chatter.messageStream.subscribe(retval=>
      {
        console.log("notifications is going to get sometthing!!!", retval);
        this.messages.push(retval);
        
      })
    
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
        if (routeChange instanceof NavigationEnd) {
          this.notificPanel.close();
        }
    });

    // let lc = localStorage.getItem("session");
    // let session = JSON.parse(lc);
    // console.log('session', session)
    // this.sessionID = session.sessionId;  
    // this.name = session.idTokenPayload.name;
     
    // console.log('seting up notification bar connection')
    // this.chatter.setupConnection(this.sessionID, this.name);
    // this._hubConnection = this.chatter.hubConnection;
 
   }
   
   public sendMessage(): void {

    

    // const data = `${this.message}`;    
    // if(data==='') return;
    // let chatRecord = {to:"All",cc:[],message: data, from: this.name, sid :this.sessionID}
    

    // this._hubConnection.invoke('Send', this.name,  JSON.stringify(chatRecord));
    // this.message = '';
  }
    
  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }
}
