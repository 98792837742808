import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { SiteReviewService } from 'app/services/site-review.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ObservableStore } from '@codewithdan/observable-store';
import { RefreshObject, StoreState, UserStoreActions } from 'app/interfaces/store-state';
import { User } from 'app/models/User';
import { split } from '@angular-devkit/core';
import { NewJobComponent } from 'app/views/site-review/new-job/new-job.component';
import { Job } from 'app/models/site-review/Job';
import { environment } from 'environments/environment';

export const isProduction = environment.production;// "https://sitereviewapi.azurewebsites.net";

@Component({
  selector: 'app-file-upload-drop-dialog',
  templateUrl: './file-upload-drop-dialog.component.html',
  styleUrls: ['./file-upload-drop-dialog.component.css']
})
export class FileUploadDropDialogComponent  extends ObservableStore<StoreState>   implements OnInit {
  @ViewChild('fileUploaderInput') fileUploaderInput: ElementRef;
  public uploader: FileUploader;
  public loading: boolean = false;
  public url: string;
  constructor(
    private siteReviewService: SiteReviewService,
    public dialogRef: MatDialogRef<FileUploadDropDialogComponent>, 
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
   
    super({ trackStateHistory: true, logStateChanges: true });
    dialogRef.disableClose = true;

   }

  ngOnInit() {
    console.log('this.data', this.data);

    console.log('this page', this.router.url)
    let apiServer = this.siteReviewService.apiServer;
    let sessionId = this.siteReviewService.sessionId;
    this.url = apiServer + '/api/import/ccm?sid=' + sessionId;


    let checkJob = this.router.url.indexOf('reviews/jobs')
    if (this.router.url.indexOf('reviews/jobs') > 0) {     
      let myPath = this.router.url.substring(checkJob)
      let jobID = myPath.split('/')[2];
      if(parseInt(jobID)>0 ){
        console.log('This is a Job, so we will change the target to the Job File upload api');
        // alert('sorry, we are still working on this part right now')
        // return ;        
      }else{
       
        this.url = `${this.siteReviewService.apiServer}/api/filemanager/analyze/1/folder/12rO5Ecg8HdrIYAbNeh-apHPgCetvVuYC?sid=${sessionId}`;
       // this.url = `${this.siteReviewService.apiServer}/api/JobAI?fileId=1z2ma3FQhgGC8J89riq4_Z6GamQowGIgV&ImportType=JobAnalyze&sid=${sessionId}`;
      }     
    }



    let checkVehicle = this.router.url.indexOf('fleet/vehicle')
    if (this.router.url.indexOf('fleet/vehicle') > 0) {     
      let myPath = this.router.url.substring(checkVehicle)
      let vehicleID = myPath.split('/')[2];
      if(parseInt(vehicleID)>0 ){
        console.log('This is a vehicle, so we will change the target to the vehicle upload api');
        this.url = `${this.siteReviewService.apiServer}/api/fleet/vehicle/${vehicleID}/image?sid=${sessionId}`;
      }      
    }


    if(this.data){
      let parentTable = this.data.parentTable || 'none';
      let parentID = this.data.parentID || '0';
      let folder = this.data.folderID || '';
      if(folder!=''){
        this.url = `${this.siteReviewService.apiServer }/api/filemanager/${parentTable}/${parentID}/folder/${folder}?sid=${this.siteReviewService.sessionId}`;
      }  
    }


    this.uploader = this.buildUploader();
  }

  //getUrl(fileName:string):string{
  getUrl(file){
    console.log("file in getUrl ", file, )
    let sessionId = this.siteReviewService.sessionId;
    let url = '';
    let fileName = file.name;

    if (this.router.url.indexOf('fleet/vehicle') > 0) {   
      let checkVehicle = this.router.url.indexOf('fleet/vehicle')
      let myPath = this.router.url.substring(checkVehicle)
      let vehicleID = myPath.split('/')[2];
      if(parseInt(vehicleID)>0 ){
        console.log('This is a vehicle, so we will change the target to the vehicle upload api');
        //if(fileName.indexOf('.jpg') >0 || fileName.indexOf('.png') >0 || fileName.indexOf('.gif') >0 || fileName.indexOf('.jpe') >0   ){
        if (file && file.type.startsWith('image/')){
          url= `${this.siteReviewService.apiServer}/api/fleet/vehicle/${vehicleID}/image?sid=${sessionId}`;
        }
        else{
          url= `${this.siteReviewService.apiServer}/api/fleet/vehicle/${vehicleID}/file?sid=${sessionId}`;
        }
      }
      return url;
    }

    let checkJob = this.router.url.indexOf('reviews/jobs')
    if (this.router.url.indexOf('reviews/jobs') > 0) {     
      let myPath = this.router.url.substring(checkJob)
      let jobID = myPath.split('/')[2];
      if(parseInt(jobID)>0 ){
        // console.log('This is a Job, so we will change the target to the Job File upload api');
        // alert('sorry, we are still working on this part right now')
        // return ;        
      }else{
        
        url = `${this.siteReviewService.apiServer}/api/filemanager/analyze/1/folder/12rO5Ecg8HdrIYAbNeh-apHPgCetvVuYC?sid=${sessionId}`;
       // this.url = `${this.siteReviewService.apiServer}/api/JobAI?fileId=1z2ma3FQhgGC8J89riq4_Z6GamQowGIgV&ImportType=JobAnalyze&sid=${sessionId}`;
      }     
    }


    
      /* ##### IS THIS A REPAIR ORDER UPLOAD #######  */

      let checkPath = this.router.url.indexOf('fleet/repair-orders')
      if (this.router.url.indexOf('fleet/repair-orders') > 0) {     
        let myPath = this.router.url.substring(checkPath)
        let repairOrderID = myPath.split('/')[2];
        if(parseInt(repairOrderID)>0 ){
          console.log('This is a repair-order, so we will change the target to the repair-orders upload api');
          url = `${this.siteReviewService.apiServer}/api/fleet/repairs/${repairOrderID}/image?sid=${sessionId}`;
        }      
      }

   

    return url;
  }

  spinner=false;
  buildUploader() {

    let url: string = this.url;
    let headers: any = [];
    const uploaderOptions: FileUploaderOptions = {
      url: url,
      // Upload files automatically upon addition to upload queue
      autoUpload: true,
      // Use xhrTransport in favor of iframeTransport
      isHTML5: true,
      // Calculate progress independently for each uploaded file
      removeAfterUpload: true,
      // XHR request headers
      headers: headers
    };
    let uploader = new FileUploader(uploaderOptions);
    uploader.onBeforeUploadItem = (item) => {



      //let url = this.getUrl(item.file.name)
      let url = this.getUrl(item.file)
      if(url.length>0){
        item.url = url
      }
      
      item.withCredentials = false;
      this.loading = true;
      console.log(item)
    }
    uploader.onErrorItem = (item, response, status) => {
      this.loading = false;
      console.log('error', item, response, status)
    }
    uploader.onCompleteItem= (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any =>{
      console.log('fileItem',item);console.log('response',response);console.log('status',status);console.log('headers',headers);
      let checkPath = item.url.indexOf('analyze') 
      if(checkPath>=0){
       
        this.spinner=true;

        let sessionId = item.url.split('sid=')[1];

        let obj = JSON.parse(response);

        
        this.siteReviewService.analyzeJobPDF(obj.gdID)
          .subscribe((retval:Job)=>{

            if(isProduction){
              retval.customerID=205;
            }else{
              retval.customerID=1087;
            }
           

            this.addJob(retval);
            this.spinner=false;
            console.log('retval',retval)
            this.dialogRef.close();
          });
        

      }else{
        this.spinner=false;
        console.log('retval',item)
        this.dialogRef.close();
      }
    }
    uploader.onCompleteAll = () => {
      this.loading = false;
      uploader.clearQueue();
     


      var refreshObject:RefreshObject=new RefreshObject();
      refreshObject.componentName='VehicleFileList';
      refreshObject.refreshRequired=true;
      const state = this.getState();
      if (state && state.refreshObject) {    
        this.setState({ refreshObject }, 'Refresher')
      }
      
      if (state && !state.refreshObject) {    
        this.setState({ refreshObject }, 'Refresher')
      }
      this.close();
    }
    return uploader;
  }

  close() {
    let counter=0
    setInterval((counter)=>{
      if(!this.spinner && counter<30){
        this.dialogRef.close();
      }
      counter++
    },2000)
    
  }

  onFileChanged(e) {
    console.log(e);
  }




   /* NEW JOB FEATURES  */
   newJobDialog

   addJob(job: any) {

    const config: MatDialogConfig = new MatDialogConfig();
    config.data = {};
    config.width = "500px";
    config.height = "700px";
    let url = this.router.url;
    let rMessage = "Return to the Jobs list"
    config.data = { redirectPage: url, redirectMessage: rMessage, job: job};
    const dialogRef = this.dialog.open(NewJobComponent, config);
    this.newJobDialog = true;
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('The new job dialog was closed');
      this.newJobDialog = false;
      //this.refreshDataStore();
    });


  }
  
}
