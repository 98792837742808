import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IdbService } from 'app/services/idb.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ChatterService } from 'app/chat/shared/services/chatter.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  constructor(    public dialog: MatDialog,
                  private dialogRef: MatDialogRef<ResetComponent>,    
                  private idb: IdbService,
                  private chatter: ChatterService

    ) { }

  ngOnInit(): void {
  }

  async clearIndexDB() {

    let config: any = {};
    config.data = {}
    config.width = "550px";
    config.height = "250px";
    config.data.title = 'Clearing Old Data';
    config.data.comment = 'Please wait a moment while we clear out some jobs from your browser\'s internal database';
    this.chatter.jobMasterIsUpdting = true;
    const dialogRef = this.dialog.open(SpinnerComponent, config).afterOpened().subscribe(async (obj) => {
      await this.idb.deleteDatabase().then(() => {
        console.log("db deleted")
        this.chatter.jobMasterIsUpdting = false
        this.dialogRef.close();
        
      })
    });
  }
    
}
