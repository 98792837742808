
<!-- <div style="width:100%;height:30px;background-color:red;color:white;text-align: center;padding-top:5px" *ngIf="showWarning">
  
  <span style="font-size: 15pt;">THIS IS DEVELOPMENT DATA.  IF YOU ARE LOOKING FOR THE BETA SITE, <a href="https://beta.pinnacleqa.com/sessions/signin" style="color:blue;text-decoration: underline;">CLICK HERE</a></span>
</div>
<div  *ngIf="showWarning" style="position: absolute;top:4px;right:6px;cursor:pointer;height:30px;padding-top:0px;color:white;font-size:16pt;" (click)="showWarning=false">X</div>
 -->
<mat-toolbar class="topbar">
  <!-- Sidenav toggle button -->
  <button mat-icon-button id="sidenavToggle" (click)="toggleSidenav()" matTooltip="Toggle Hide/Open">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="example-fill-remaining-space"></span>
  <div fxFlex="10" class="pl-1"> 
    <button mat-icon-button  (click)="fileUploadDropZoneDlg()"  matTooltip="Upload Files">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>
  <button mat-icon-button class="topbar-button-right"  (click)="logout()" matTooltip="Signout">
    <mat-icon>exit_to_app</mat-icon>
  </button>
</mat-toolbar>  

 
  <!-- Sidenav toggle collapse -->
  <!--
  <button 
  *ngIf="sidenav.opened"
  mat-icon-button
  id="collapseToggle"
  fxHide.lt-md="true" 
  (click)="toggleCollapse()"
  matTooltip="Toggle Collapse"
  class="toggle-collapsed">
  <mat-icon>chevron_left</mat-icon>
  </button>
  -->

  <!-- Search form -->
  <!-- <div 
  fxFlex
  fxHide.lt-sm="true" 
  class="search-bar">
    <form class="top-search-form">
      <mat-icon role="img">search</mat-icon>
      <input autofocus="true" placeholder="Search" type="text">
    </form>
  </div> -->
  <span fxFlex></span>

  <!-- Theme Switcher -->

  <!-- <button mat-icon-button id="schemeToggle" [style.overflow]="'visible'" matTooltip="Color Schemes"
    [matMenuTriggerFor]="themeMenu" class="topbar-button-right">
    <mat-icon>format_color_fill</mat-icon>
  </button>
  <mat-menu #themeMenu="matMenu">
    <mat-grid-list class="theme-list" cols="2" rowHeight="48px">
      <mat-grid-tile *ngFor="let theme of egretThemes" (click)="changeTheme(theme)">
        <div mat-menu-item [title]="theme.name">
          <div [style.background]="theme.baseColor" class="egret-swatch"></div>
          <mat-icon class="active-icon" *ngIf="theme.isActive">check</mat-icon>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-menu> -->


  <!-- Notification toggle button -->
  <!-- <button 
  mat-icon-button
  matTooltip="Notifications" 
  (click)="toggleNotific()"
  [style.overflow]="'visible'" 
  class="topbar-button-right">
    <mat-icon>notifications</mat-icon>
    <span class="notification-number mat-bg-warn">3</span>
  </button> -->
  <!-- Top left user menu -->
  
  <mat-menu #accountMenu="matMenu">
<!--     
    <button mat-menu-item [routerLink]="['/admin/overview']">
      <mat-icon>account_box</mat-icon>
      <span>Profile</span>
    </button> 
  -->
   
    <button mat-menu-item [routerLink]="['reviews/pages/about-dash']">
      <mat-icon>article</mat-icon>
      <span>About Dash</span>
    </button> 

    <!-- <button mat-menu-item (click)="refreshData()">
      <mat-icon>sync</mat-icon>
      <span>Refresh Data</span>
    </button>  -->
    
    <button mat-menu-item (click)="refreshJobList()">
      <mat-icon>sync</mat-icon>
      <span>Refresh Job List</span>
    </button> 
    
    <button mat-menu-item (click)="clearIndexDB()">
      <mat-icon>settings_backup_restore</mat-icon>
      <span>Reset Local Data</span>
    </button> 
    
    
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sign out</span>
    </button>
  </mat-menu>

  <!-- <button mat-button (click)="clearIndexDB()">Refresh</button> -->

  <!-- <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" /> -->

   <!-- <button mat-icon-button type="button" style="color:white;position:absolute;top:10px;right:50px;" (click)="help()">
    <mat-icon>help</mat-icon>
  </button> -->

  <button mat-icon-button type="button" style="color:white;position:absolute;top:10px;right:10px;" [matMenuTriggerFor]="accountMenu">
  <mat-icon>settings</mat-icon>
</button>

<!-- 
  <button mat-icon-button type="button" style="position:absolute;top:10px;right:10px;" *ngIf="!showChatter"
    (click)="toggleChatterBox()">
    <mat-icon>message</mat-icon>
  </button>
  <button mat-icon-button type="button" aria-hidden="false" style="position:absolute;top:10px;right:10px;" *ngIf="showChatter"
    (click)="toggleChatterBox()">
    <mat-icon>close</mat-icon>
  </button>
  <div [ngClass]="chatterBoxClass">
    <app-chat></app-chat>
  </div> -->
