<md-dialog>
  <h2 mat-dialog-title>{{title}}</h2>

  <md-dialog-content>
    <svg class="spinner" width="265px" height="265px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>

    </svg>
    <div style="padding:10px">
      <span class="text-small">{{comment}}</span>
    </div>
  </md-dialog-content>
</md-dialog>