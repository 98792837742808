export interface RequestOptions { headers?: HttpHeaders | { [header: string]: string | Array<string> }; body?: any; search?: any }

import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PurchaseOrder } from '../models/purchase-order'
import { Quote } from '../models/quote'
import { PurchaseOrderItem } from '../models/purchase-order-item'

import { environment } from '../../environments/environment';
import { GoogleFolderCommandSet } from 'app/models/google-folder-command-set';
import { Command } from 'app/models/command';

import {  Observable, throwError as observableThrowError } from 'rxjs';   
import { catchError, map, mapTo, share } from 'rxjs/operators';


@Injectable()
export class DatalayerService {

  public sessionId: string;
  public apiServer: string = ''
  public fileUploadUrl: string = 'api/Files';
  private loginUrl: string = 'api/Login';


  constructor(private _http: HttpClient) {
    // let ss = localStorage.getItem("sr_session_id");
    // if (ss) {
    //   let sessionObj: any = JSON.parse(ss);
    //   this.sessionId = sessionObj.sessionId
    // }
    this.sessionId = localStorage.getItem("sr_session_id");
    this.apiServer = environment.serverURL

  }
  Login(username: string, password: string) {

    let o = { username: username, password: password };
    return this._http.post(this.apiServer + '/' + this.loginUrl, o)
      .pipe(
        map(res => res), catchError(this.handleError) 
      )

  }
  public printReport(reportId, fieldSets) {
    let url = this.apiServer + "/api/ccsc/" + reportId + "?format=link&sid=" + this.sessionId;
    return this._http.post(url, fieldSets).map(res => res);
  }


  public renameGoogleFile(fileID, name) {

    name = encodeURI(name);
    let url = this.apiServer + "/api/FileFolderRename/" + fileID + "?name=" + name + "&sid=" + this.sessionId;
    return this._http.get(url);

  }

  public splitGooglePDFFile(obj) {

    let url = this.apiServer + "/api/pdfsplitter/?sid=" + this.sessionId;
    return this._http.post(url, obj);

  }

  public resetJobFolderNames(jobID) {


   // let url = this.apiServer + "/api/ResetJobFolderNames/" + jobID + "?sid=" + this.sessionId;
   // return this._http.get(url);

  }
  public runFileImportRoutine() {

    let url = this.apiServer + "/api/automation/import/AZURE?sid=" + this.sessionId;
    return this._http.get(url);

  }
  public deleteGoogleFile(fileID) {

    let url = this.apiServer + "/api/Files?filename=" + fileID + "&sid=" + this.sessionId;
    return this._http.delete(url);


  }
  public makeGoogleFolder(folderObject: GoogleFolderCommandSet): Observable<any> {
    alert('If you get this popup, please contact Tyler in Internal Operations and send him a screenshot. Sorry for any inconvenience')
    let url = this.apiServer + "/api/filefolder?sid=" + this.sessionId;
    return this._http.post(url, folderObject);
  }

  //deprecated.  now in google-drive.service
  public getGoogleFolder(googleParentID: string): Observable<any> {
    let url = this.apiServer + "/api/filefolder/" + googleParentID + "?sid=" + this.sessionId;
    return this._http.get(url);
  }

  public getGoogleFolderByPath(filePath: string) {
    let url = this.apiServer + "/api/files?directory=" + filePath + "?sid=" + this.sessionId;
    return this._http.get(url);
  }

  // moved to google-drive.service
  // public moveGoogleFile(fileID:string,parentID:string){
  // let obj:any={"fileID":fileID,"parentID":parentID};
  // let url = this.apiServer + "/api/FilesMove?sid=" + this.sessionId;    
  // return this._http.post(url,obj);
  // }

  // deprecated
  // public ChangeCustomerFolders(BidFolderID: string, CustomerFolderID: string) {
  //   let obj: any = { "BidFolderID": BidFolderID, "CustomerFolderID": CustomerFolderID };
  //   let url = this.apiServer + "/api/CustomerFolderMove?sid=" + this.sessionId;
  //   return this._http.post(url, obj);

  // }
  public GetAll = (): Observable<any> => {
    return this._http.get(this.loginUrl)
    .pipe(
      map(res => res), catchError(this.handleError)
    )
    
  }
  public getJob = (id): Observable<any> => {
    let url: string = this.apiServer + '/api/job/' + id + '?sid=' + this.sessionId;
    return this._http.get(url)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));
  }
  public getJobs = (): Observable<any> => {
    let url: string = this.apiServer + '/api/job' + '?sid=' + this.sessionId;
    return this._http.get(url)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));
  }
  saveJob(job) {
    alert("this script is no longer in use.  Please notify your development team if you see this message")
    // let url: string = this.apiServer + '/api/job' + '?sid=' + this.sessionId;
    // if (!job.JobID) {
    //   return this._http.post(url, job)
    //     .pipe(
    //       map(res => res), catchError(this.handleError)
    //     )
    // }
    // return this._http.put(url, job)
    // .pipe(
    //   map(res => res), catchError(this.handleError)
    // )
  }
  public getQuotes = (): Observable<any> => {
    let url: string = this.apiServer + '/api/Quote' + '?sid=' + this.sessionId;
    return this._http.get(url)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));
  }
  public getQuote = (id: number): Observable<any> => {
    // temp
    let url: string = this.apiServer + '/api/Quote' + '?sid=' + this.sessionId;
    return this._http.get(url)
      .map((response: any) => {
        let quotes = response;
        return quotes.filter(item => item.PurchaseOrderID == id)[0];
      });

    // let url: string = this.apiServer + '/api/Quote/' + id + '?sid=' + this.sessionId;
    // return this._http.get(url)
    //   .map((response: any)  => <any>response);
    //  .do(x => console.log(x));
  }
  public saveQuote(quote: Quote): Observable<any> {
    let url: string = this.apiServer + '/api/Quote?sid=' + this.sessionId;
    return this._http.post(url, quote)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));
  }




  //========== PO ============
  public getPurchaseOrders(params?: any): Observable<any> {
    // let url: string = this.apiServer + '/api/Quote' + '?sid=' + this.sessionId;
    let url: string = this.apiServer + '/api/PurchaseOrder' + '?sid=' + this.sessionId;
    url = url + '&' + params;

    return this._http.get(url)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));
  }
  public getPurchaseOrder(id: string): Observable<any> {
    // let url: string = this.apiServer + '/api/Quote/' + id + '?sid=' + this.sessionId;
    let url: string = this.apiServer + '/api/PurchaseOrder/' + id + '?sid=' + this.sessionId;
    return this._http.get(url)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));
  }
  public savePurchaseOrder(Po: PurchaseOrder): Observable<any> {
    if (!Po.PurchaseOrderID) {
      let url: string = this.apiServer + '/api/Quote?sid=' + this.sessionId;
      return this._http.post(url, Po)
        .map((response: any) => <any>response);
    } else {
      let url: string = this.apiServer + '/api/PurchaseOrder?sid=' + this.sessionId;
      return this._http.put(url, Po)
        .map((response: any) => <any>response);
    }
  }
  public getPurchaseOrderItems(id: number, params?): Observable<any> {
    let url: string = this.apiServer + '/api/PurchaseOrderItems/' + id + '?sid=' + this.sessionId;
    url = url + '&' + params;
    return this._http.get(url)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));
  }
  public deletePurchaseOrderItem(id: string): Observable<any> {
    let url: string = this.apiServer + '/api/PurchaseOrderItems/' + id + '?sid=' + this.sessionId;
    return this._http.delete(url)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));
  }
  public insertPurchaseOrderItem(obj: PurchaseOrderItem): Observable<any> {
    let url: string = this.apiServer + '/api/PurchaseOrderItems?sid=' + this.sessionId;
    return this._http.post(url, obj)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));
  }

  public getDashboard() {
    let url: string = this.apiServer + '/api/Dashboard?sid=' + this.sessionId;
    return this._http.get(url)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));
  }

  

  //========== FILE ============
  public getFileDirectory = (directory: string): Observable<any> => {
    let url: string = this.apiServer + '/api/Files?directory=' + directory + '&sid=' + this.sessionId;
    return this._http.get(url)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));
  }
  public getFileUrl(filename: string, minutes: number) {
    let url: string = this.apiServer + '/api/Files?filename=' + filename + '&mins=' + minutes.toString() + '&sid=' + this.sessionId;
    return this._http.get(url)
      .map((response: any) => <any>response);
    //  .do(x => console.log(x));

  }
  public deleteFile(filename: string) {
    let obj: any = { fileName: filename, sessionId: this.sessionId };
    let url: string = this.apiServer + '/api/Files?filename=' + filename + '&sid=' + this.sessionId;


    return this._http.delete(url)
  }
  public getUserList(): any {
    //moved to site-review-service
  }

  public getUser(id: number): any {
    //moved to site-review-service
    // let url: string = this.apiServer + '/api/User/' + id + '?sid=' + this.sessionId;
    // return this._http.get(url)
    //   .map((response: any) => <any>response);
  }

  //========== LINK/UNLINK JOB AND PO ============
  public linkPOtoJob(PurchaseOrderID, JobID) {
    let obj = { "PurchaseOrderID": PurchaseOrderID, "JobID": JobID };

    let url: string = this.apiServer + '/api/JobPurchaseOrderLink?sid=' + this.sessionId;
    return this._http.post(url, obj)
      .map((response: any) => <any>response);
  }
  public unlinkPOfromJob(PurchaseOrderID, JobID) {
    let url: string = `${environment.serverURL}/api/JobPurchaseOrderLink/${JobID}?poid=${PurchaseOrderID}&sid=${this.sessionId}`;
    return this._http.delete(url)
      .map((response: any) => <any>response);
  }
  public getJobPOList(id: number): Observable<any> {
    return this._http.get(`${environment.serverURL}/api/JobPurchaseOrder/${id}?sid=${this.sessionId}`)
    .pipe(
      map(res => res), catchError(this.handleError), share()
    )
 
  }

  public getUnLinkedPOs(): Observable<any> {
    return this._http.get(`${environment.serverURL}/api/JobPurchaseOrderLink?sid=${this.sessionId}`)
    .pipe(
      map(res => res), catchError(this.handleError), share()
    )
 
  }
  //============ JOB MATERIALS =================
  public getJobMaterialList(jobID: number): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/Material?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
 
  }
  public saveJobMaterial(jobID: number, material: any): Observable<any> {
    if (material.JobMaterialID) {
      let url: string = this.apiServer + '/api/Job/' + jobID + '/Material/' + material.JobMaterialID + '?sid=' + this.sessionId;
      return this._http.put(url, material)
      .pipe(
        map(res => res), catchError(this.handleError) 
      )
    } else {
      let url: string = this.apiServer + '/api/Job/' + jobID + '/Material?sid=' + this.sessionId;
      return this._http.post(url, material)
      .pipe(
        map(res => res), catchError(this.handleError) 
      )
    }
  }
  public deleteJobMaterial(jobID, JobMaterialID): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/Material/' + JobMaterialID + '?sid=' + this.sessionId;
    return this._http.delete(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  //============= JOB COST ==============
  public getJobCosts(jobID): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/JobCost?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public saveJobCost(jobID, jobCost): Observable<any> {
    if (jobCost.JobCostID) {
      let url: string = this.apiServer + '/api/Job/' + jobID + '/JobCost/' + jobCost.JobCostID + '?sid=' + this.sessionId;
      return this._http.put(url, jobCost)
      .pipe(
        map(res => res), catchError(this.handleError) 
      )
    } else {
      let url: string = this.apiServer + '/api/Job/' + jobID + '/JobCost?sid=' + this.sessionId;
      return this._http.post(url, jobCost)
      .pipe(
        map(res => res), catchError(this.handleError) 
      )
    }
  }
  public deleteJobCost(jobID, jobCostID): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/JobCost/' + jobCostID + '?sid=' + this.sessionId;
    return this._http.delete(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public getJobCostMatTotal(jobID, billingWeek, type) {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/JobInvoice/' + billingWeek + '?sid=' + this.sessionId + '&type=' + type;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public saveJobCostMatBackout(data, type) {
    let url: string = this.apiServer + '/api/JobInvoice?sid=' + this.sessionId + '&type=' + type;
    return this._http.put(url, data)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }


  // ============ ACCRUAL AN ACCRUAL ITEMS ============
  public getJobAccruals(jobID): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/Accrual?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public saveJobAccruals(jobID, accrual): Observable<any> {
    if (accrual.AccrualID) {
      let url: string = this.apiServer + '/api/Job/' + jobID + '/Accrual/' + accrual.AccrualID + '?sid=' + this.sessionId;
      return this._http.put(url, accrual)
      .pipe(
        map(res => res), catchError(this.handleError) 
      )
    } else {
      let url: string = this.apiServer + '/api/Job/' + jobID + '/Accrual?sid=' + this.sessionId;
      return this._http.post(url, accrual)
      .pipe(
        map(res => res), catchError(this.handleError) 
      )
    }
  }
  public getJobAccrualItems(jobID): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/Accrual?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public saveJobAccrualItems(jobID, billingWeekID, accrualItems): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/AccrualItem?BillingWeekID=' + billingWeekID + '&sid=' + this.sessionId;
    return this._http.post(url, accrualItems)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }

  // ========= INVOICE ITEM =========
  public saveJobInvoiceItems(jobID, billingWeekID, invoiceItems): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/JobInvoiceItem?BillingWeekID=' + billingWeekID + '&sid=' + this.sessionId;
    return this._http.post(url, invoiceItems)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }

  // =========== JOB ITEM ============
  public getJobItemsByWeek(jobID: number, weekID): Observable<any> {
    let url: string = this.apiServer + '/api/JobProductItems/' + jobID + '?week_id=' + weekID + '&sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }

  public getJobItemList(jobID: number): Observable<any> {
    let url: string = this.apiServer + '/api/JobProductItems/' + jobID + '?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public saveJobItem(item): Observable<any> {
    let url: string = this.apiServer + '/api/JobLineItem?sid=' + this.sessionId;
    return this._http.put(url, item)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }

  //=========== JOB ITEM BREAK OUT ==========
  public getJobItemBO(jobID): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/LineBreakout?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public getJobItemBOByItem(jobID, itemID): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/LineBreakout/' + itemID + '?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public saveJobItemBOs(jobID, BOList: any[]): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/LineBreakout?sid=' + this.sessionId;
    return this._http.post(url, BOList)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public updateJobItemBO(boID, BO): Observable<any> {
    let url: string = this.apiServer + '/api/LineBreakout/' + boID + '?sid=' + this.sessionId;
    return this._http.put(url, BO)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public deleteJobItemBo(boID): Observable<any> {
    let url: string = this.apiServer + '/api/LineBreakout/' + boID + '?sid=' + this.sessionId;
    return this._http.delete(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }

  getJobDirectNonBillbackCosts(JobId: number): Observable<any> {
    let o: any = [{
      Description: '45 CLASS 3 POLE 1',
      Cost: 10000,
      JobCosted: 0,
      Remaining: 0,
      CostCode: 'MATERIAL'
    },
    {
      Description: '45 CLASS 3 POLE 2',
      Cost: 4000,
      JobCosted: 0,
      Remaining: 0,
      CostCode: 'MATERIAL'
    }]
    return Observable.of(new Object()).pipe(mapTo(o));
  }

  getJobDirectBillbackCosts(JobId: number): Observable<any> {
    let o: any = [{
      Description: '45 CLASS 3 POLE 1',
      Quantity: 10,
      Po: 2321,
      PoLine: 'Line 1',
      Rate: 2000
    },
    {
      Description: '45 CLASS 3 POLE 2',
      Quantity: 12,
      Po: 3212,
      PoLine: 'Line 2',
      Rate: 5000
    }]
    return Observable.of(new Object()).pipe(mapTo(o));
  }
  //================ BILLING WEEK =================
  public getBillingWeeks() {
    let url: string = this.apiServer + '/api/BillingWeek/?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public getJobBillingWeeks(jobID): Observable<any> {
    let url: string = this.apiServer + '/api/Job/' + jobID + '/BillingWeek/?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public getJobBillingAccrualHistory(jobID): Observable<any> {
    let url: string = this.apiServer + '/api/JobBillingAndAccrualHistory/' + jobID + '?sid=' + this.sessionId
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }

  public getOpsWidgetSummary(): Observable<any> {
    let data = [
      { type: 'quote', count: 40, value: "$23,334" },
      { type: 'job', count: 13, value: "$13,334" },

    ];
    return Observable.of(new Object()).pipe(mapTo(data));
  }
  public getOpsWidgetDetails(): Observable<any> {
    // let data = [
    //   { type: "quote", ID: "726", text: "Quote by john doe for $2038" },
    //   { type: "quote", ID: "726", text: "Quote by john doe for $2038" },
    //   { type: "quote", ID: "726", text: "Quote by john doe for $2038" },
    //   { type: "quote", ID: "726", text: "Quote by john doe for $2038" },
    //   { type: "po", ID: "EXT-0000219866", text: "PO  by john doe for $2038" },
    //   { type: "po", ID: "EXT-0000219866", text: "PO by john doe for $2038" },
    //   { type: "po", ID: "EXT-0000219866", text: "PO by john doe for $2038" },
    //   { type: "po", ID: "EXT-0000219866", text: "PO by john doe for $2038" },
    //   { type: "po", ID: "EXT-0000219866", text: "PO by john doe for $2038" },
    //   { type: "job", ID: "8", text: "Job by john doe for $2038" },
    //   { type: "job", ID: "8", text: "Job by john doe for $2038" },
    //   { type: "job", ID: "8", text: "Job by john doe for $2038" },
    //   { type: "job", ID: "8", text: "Job by john doe for $2038" }
    // ];
    // return Observable.of(new Object()).mapTo(data);

    return this._http.get(`${environment.serverURL}/api/ViewHistory?sid=${this.sessionId}`)
      .pipe(
        map(res => res), catchError(this.handleError) 
      )
  }

  //============== TASK ==============
  public getTasks(): Observable<any> {
    let url: string = this.apiServer + '/api/Task' + '?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public updateTask(task): Observable<any> {
    let url = this.apiServer + '/api/Task?sid=' + this.sessionId;
    return this._http.put(url, task)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public insertTask(task): Observable<any> {
    let url = this.apiServer + '/api/Task?sid=' + this.sessionId;
    return this._http.post(url, task)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public deleteTask(id): Observable<any> {
    let url = this.apiServer + '/api/Task/' + id + '?sid=' + this.sessionId;
    return this._http.delete(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }

  //=============== IT TRACKER ==============
  public getItTrackers() {
    let url: string = this.apiServer + '/api/ITTracker' + '?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public saveItTracker(tracker) {
    let url: string = this.apiServer + '/api/ITTracker' + '?sid=' + this.sessionId;
    if (tracker.ITTrackerID) {
      return this._http.put(url, tracker)
      .pipe(
        map(res => res), catchError(this.handleError) 
      )
    } else {
      return this._http.post(url, tracker)
      .pipe(
        map(res => res), catchError(this.handleError) 
      )
    }
  }

  //============= Message Board =============
  public getMessages(): Observable<any> {
    let url: string = this.apiServer + '/api/Message' + '?sid=' + this.sessionId;
    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public saveMessage(msg) {
    let url: string = this.apiServer + '/api/Message' + '?sid=' + this.sessionId;
    return this._http.post(url, msg)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }
  public saveComment(msg) {
    let url: string = this.apiServer + '/api/Message' + '?sid=' + this.sessionId;
    return this._http.put(url, msg)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )
  }

  public pollMessage(messageCount: number) {
    let url = this.apiServer + '/api/MessageCount?messageCount=' + messageCount + '&sid=' + this.sessionId;
  
  }
  // Customer
  public getCustomers(): Observable<any> {

    let cmd = new Command();
    cmd.procedure = "cmdCustomers"

    return this.command(cmd);


  }
  public getCustomerByID(id): Observable<any> {
    let data = {
      "_id": "5a9a67f0a1de92e21d352e9a",
      "Active": true,
      "FirstName": "Obrien",
      "LastName": "Hull",
      "Company": "QIMONK",
      "Email": "obrienhull@qimonk.com",
      "Phone": "+1 (978) 539-2395",
      "Address": "844 Cozine Avenue, Venice, Utah, 4882",
      "Contacts": [{
        "FirstName": "Obrien",
        "LastName": "Hull",
        "Company": "QIMONK",
        "Email": "obrienhull@qimonk.com",
        "Phone1": "+1 (978) 539-2395",
        "Phone2": "+1 (978) 432-5674",
        "Address": "844 Cozine Avenue, Venice, Utah, 4882",
      }]
    }
    return Observable.of(data);
  }

  command(commandObject: any): Observable<any> {

    let url: string = this.apiServer + '/api/cmd?sid=' + this.sessionId;
    console.log(url);
    return this._http.post(url, commandObject)
      .map((response: Response) => <any>response);
    //  .do(x => console.log(x));
  }


  // TEST FUNCTION -----
  public runSQL(sql: string) {
    //sql = encodeURIComponent(sql);
    //sql="select top 10 * from p_client"
    let url = `https://fm.morrisdev.com/svr/api/values?q=${sql}&sid=thisisasecretkeyforpinnacle`;

    return this._http.get(url)
    .pipe(
      map(res => res), catchError(this.handleError) 
    )

  }




  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status < 200 || error.status >= 300) {
        errMsg = 'This request has failed ' + error.status + ' - ' + error.statusText;
      } else {
        errMsg = `${error.status} - ${error.statusText || ''}`;
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return observableThrowError(errMsg || 'Server Error');
  }
}

