import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TemplateView } from 'app/models/template-view';
 
@Component({
  selector: 'app-grid-template-dialog',
  templateUrl: './grid-template-dialog.component.html',
 })
export class GridTemplateDialog implements OnInit {

  
  template: TemplateView
  myForm: FormGroup;
  pageCode:string = 'NONE';
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder ) {
      this.pageCode = data.pageCode;
      this.buildForm();
  }
  buildForm(obj:any = {}) {
     this.myForm = this.fb.group({
      templateViewID: new FormControl(obj.templateViewID || 0),
      templateViewName: new FormControl(obj.templateViewName || '', Validators.required),
      globalView: new FormControl(obj.globalView || false,),
      publicView: new FormControl(obj.publicView || false),
      status: new FormControl('Active'),
      pageCode: new FormControl(this.pageCode),
       
     
    })
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  btnCancel(): void {
    this.dialogRef.close();
  }

  btnSave(): void {


    this.dialogRef.close(this.myForm.value);
  }
  btnDelete(): void {
    this.myForm.controls["status"].setValue("DELETED");
    this.dialogRef.close(this.myForm.value);
  }
  isDefault = false;
  ngOnInit(): void {


    console.log("I got this data", this.data)
    this.template = this.data;
    if (this.template.templateViewID === 0) {
      this.template.globalView = false;
      this.template.publicView = false;
    }
    var obj = this.template;

    //ANNOYING SQL SERVER STUFF
    if (obj.publicView.toString() == "False") { obj.publicView = false; }
    if (obj.globalView.toString() == "False") { obj.globalView = false; }
    if (obj.globalView.toString() == "True") { obj.publicView = true; }
    if (obj.globalView.toString() == "True") { obj.globalView = true; }
    //END ANNOYING SQL SERVER STUFF

    if (obj.templateViewName == 'Default') this.isDefault = true;
    this.myForm = this.fb.group({
      templateViewID: new FormControl(obj.templateViewID || 0),
      templateViewName: new FormControl(obj.templateViewName || '', Validators.required),
      globalView: new FormControl(obj.globalView || false,),
      publicView: new FormControl(obj.publicView || false),
      status: new FormControl('Active'),
      pageCode: new FormControl('JOBMASTER'),
    });


    // this.db.getAssignmentTeam(0)
    //   .subscribe(retval=>{
    //     this.myTeams = retval;
    //     console.log(this.myTeams);
    //   })



    //this.mySelection = this.data.assignment;
  }
}
