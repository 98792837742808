<!-- <h2 mat-dialog-title>{{this.data?.title}}</h2> -->
<h4 mat-dialog-title>
    <span *ngIf="refreshSession"> Session about to expire</span>
    <span *ngIf="logOutSession"> Session Expired</span>
    <span class="fill-space"></span>
    <span flex></span>
</h4>
<mat-dialog-content>
    <div *ngIf="refreshSession" class="sessionOut-wrapper">
        <h3>Your session is about to expire. </h3>
        <a (click)="refreshUserSession()" class="btn-Login">Renew</a>

    </div>
    <div *ngIf="logOutSession" class="sessionOut-wrapper">
        <h3>You have been logged off due to inactivity.</h3>
        <!-- <a href="sessions/signin" (click)="logOut()" class="btn-Login">Login</a> -->
        <a (click)="logOut()" class="btn-Login">Login</a>

    </div>
</mat-dialog-content>
