import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IdbService } from './idb.service';
import { Command } from 'app/models/command';
import { ToolboxService } from 'app/toolbox.service';
import { SiteReviewService } from './site-review.service';
import { map } from 'rxjs/operators';
import { RowComp } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class BulkLoaderService {

  constructor(private idb: IdbService, private tb: ToolboxService, private db: SiteReviewService) { }

  jobListTrigger = new BehaviorSubject(0)
  percentage = new BehaviorSubject(0);
  private _packageCount;
  private _countDown;
  private _percentage: number = 0;

  async bulkLoader(gridApi) {

    let commands = [];

    await this.idb.clearData('jobs')
    if (gridApi) gridApi.setRowData([]);

    for (var y = 2020; y <= new Date().getFullYear(); y++) {
      for (var m = 0; m <= 11; m++) {
        var d = new Date(y, m, 1);
        if (d <= new Date()) {
          console.log('date', d)
          var endDate;
          if (m == 11) {
            endDate = new Date(y + 1, 0, 1);
          } else {
            endDate = new Date(y, m + 1, 1);
          }
          commands.push({ startDate: d, endDate: endDate })
        }
      }
    }

    this._packageCount = commands.length;
    this._countDown = commands.length;

    commands = commands.reverse();
    commands.forEach(cmd => {
      this.getChunk(cmd.startDate, cmd.endDate, gridApi)
    });

  }


  private getChunk(b, e, gridApi) {

    let beginDate = this.tb.formatJsToSqlDate(b);
    let endDate = this.tb.formatJsToSqlDate(e);
    let cmd = new Command();
    cmd.procedure = 'cmdJobsMasterUpdateChunked';
    cmd.addParameter('BeginDate', beginDate);
    cmd.addParameter('EndDate', endDate);
    this.db.command(cmd)
      .pipe(
        map(retval => {
          retval.forEach(row => {
            try {
              row.updatedDate = new Date(row.updatedDate)

            } catch {
              console.warn('ERROR CONVERTING UPDATED DATE',row)
            }
          });
          return retval;
        })
      )
      .subscribe({
        next: (rows: any) => {


          if (gridApi) {
            try {
              gridApi.applyTransaction({ add: rows });

            } catch (e) {
              setTimeout(() => {
                try {
                  gridApi.applyTransaction({ add: rows });
                } catch (e) {
                  console.log('Grid still updating...', e)
                }

              }, 500);
              console.log('Grid currently updating...')
            }
          }


          //then we update the local DB
          this.idb.insertBulk('jobs', rows, () => {
            console.log('done inserting ' + rows.length + ' rows');
            this._countDown = this._countDown - 1;
            this._percentage = ((this._packageCount - this._countDown) / this._packageCount) * 100
            this.percentage.next(this._percentage);
          });


        }
      })


  }

  async updateLoader(gridApi) {

    let ed: Date = new Date("1/1/2020");
    var dbLastModDate = await this.idb.updateStatus('first')
    if (dbLastModDate.length > 0) {
      ed = new Date(dbLastModDate[0]);
    } else {
      this.bulkLoader(gridApi);
      return;
    }


    let sqlExpireDate: string = ed.toLocaleDateString() + ' ' + ed.toLocaleTimeString();

    console.log('retrieving updated data starting ' + sqlExpireDate)

    let cmd = new Command();
    cmd.procedure = "cmdJobsMasterUpdateLimited";
    cmd.addParameter("ExpireDate", sqlExpireDate);
    this.db.command(cmd)
      .subscribe({
        next: async retval => {

          let toUpdate: Array<any> = [];
          if (gridApi) {


            //cycle through the result set and update rows that match.
            gridApi.forEachNode((node) => {
              let check = retval.filter(r => r.jobID == node.data.jobID)
              if (check.length > 0) {
                node.updateData(check[0]);  //update the grid
                toUpdate.push(check[0]);    //add to update array for indexeddb
              }
              //remove the updated record from the result set
              retval = retval.filter(r => r.jobID != node.data.jobID)
            });

            //anything left gets added to the top
            gridApi.applyTransaction({ add: retval, addIndex: 0 });
          }
          //then we update the local DB

          await this.idb.insertBulk('jobs', retval, function () { console.log('done inserting') });
          await this.idb.updateBulk('jobs', toUpdate, function () { console.log('done updating') });

        }
      });
  }

}
