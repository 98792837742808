import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from '@angular/flex-layout';
//import { TranslateModule } from 'ng2-translate/ng2-translate';
import { TranslateModule } from '@ngx-translate/core';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion'
import { MatProgressBarModule, MatProgressBar } from '@angular/material/progress-bar';

import { TopbarComponent } from './topbar/topbar.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
 import { ThemeService } from '../../services/theme/theme.service';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploadComponent } from './file-upload/file-upload.component';


import { ChatComponent } from 'app/chat/chat.component';
import { FileUploadDropDialogComponent } from './file-upload-drop-dialog/file-upload-drop-dialog.component';

import { MatDialogModule } from '@angular/material/dialog';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { GaugeComponent } from './gauge/gauge.component';
import { GridTemplateDialog } from './grid-template-dialog/grid-template-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { HistoryDialogComponent } from './history-dialog/history-dialog.component';
import { HistoryRowDialogComponent } from './history-row-dialog/history-row-dialog.component';
//import { SafeIframeComponent,  IframeDispalyComponent} from '../../components/common/iframe-component/iframe-component';
import { CommonPipesModule } from 'app/pipes/common-pipes.module';
import { FrameComponent } from './iframe/frame.component';
import { MultiSelectComponent } from '../multi-select/multi-select.component';
import { ResetComponent } from './reset/reset.component';
import { HistoryComponent } from './history/history.component';
import { SessionTimedOutComponent } from './session-timed-out/session-timed-out.component';
import { ButtonRendererComponent } from './button-renderer/button-renderer.component';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { SpinnerComponent } from './spinner/spinner.component';
import { SupportTicketComponent } from './support-ticket/support-ticket.component';
 
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommonPipesModule,
    ReactiveFormsModule ,
    MatCardModule, MatInputModule, MatCheckboxModule, MatListModule, MatButtonModule, MatDialogModule,
     MatSelectModule,
     MatRadioModule, MatExpansionModule, MatProgressBarModule,
    
    //  BrowserModule,
    RouterModule,
    // FlexLayoutModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    // MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatSnackBarModule,  //this is basically toaster popups
    MatGridListModule,
    MatToolbarModule,
    // MatButtonModule,
    // MatRadioModule,
    // MatCheckboxModule,
    MatCardModule,
     MatExpansionModule,
    // CommonDirectivesModule,
    // TranslateModule,
    // DropDownsModule,
     MatProgressSpinnerModule,
    FileUploadModule,
    MatProgressBarModule,
    MatDialogModule,
  ],
  declarations: [
    AdminLayoutComponent,
    AuthLayoutComponent,
    TopbarComponent,
    NavigationComponent,
    NotificationsComponent,
    BreadcrumbComponent,
    FileUploadComponent,
    ChatComponent,
    FileUploadDropDialogComponent,
    PieChartComponent,
    GaugeComponent,
    GridTemplateDialog,
    ImageViewerComponent,
    HistoryDialogComponent,
    HistoryRowDialogComponent,
    FrameComponent,
    MultiSelectComponent,
    ResetComponent,
    HistoryComponent,
    SessionTimedOutComponent,
    ButtonRendererComponent,
    SupportTicketComponent, 
         // SafeIframeComponent,
   // IframeDispalyComponent,
  ],
  providers: [ThemeService],
  exports: [MultiSelectComponent,FrameComponent, FileUploadComponent, MatSpinner,  MatProgressBar, ChatComponent, CommonModule, PieChartComponent, GaugeComponent, GridTemplateDialog, HistoryComponent],
  entryComponents: [FileUploadDropDialogComponent,GridTemplateDialog]
})
export class AppCommonModule { }