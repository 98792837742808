import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class IdbService {
  version = 21;
  databaseName = 'pinnacle';
  db;


  // good resource: https://www.w3docs.com/learn-javascript/javascript-indexeddb.html
  constructor() {
  }


  async openDB(version: number = 0) {

    var request;
    if (version == 0) {
      request = indexedDB.open(this.databaseName);
    } else {
      request = indexedDB.open(this.databaseName, version);
    }

    return new Promise(async (resolve, reject) => {
      request.onsuccess = () => {
        console.log("successfully opened database")
        this.db = request.result;
        resolve(request);
      }
      request.onerror = () => { console.log('error opening db', request); reject(request) }
    })
  }

  async initializeDB() {

    if (this.db) {
      return new Promise((resolve, reject) => {
        if (this.db)
          resolve(this.db)
      })
    }



    console.log('initializing IndexedDB v'+this.version)
    let name = this.databaseName; let version = this.version;
    if (!('indexedDB' in window)) { console.error('This browser doesn\'t support IndexedDB'); return; }
    console.log('opening request to indexedDB')

    var request = indexedDB.open(this.databaseName, version);
    return new Promise((resolve, reject) => {

      request.onsuccess = () => {
        console.log("successfully opened database")
        this.db = request.result;
        resolve(request);
      }
      request.onblocked = () => {
        console.log('error opening db - blocked', request);
        reject(request);
      }
      request.onerror = () => {
        console.log('error opening db', request);
        reject(request)
      }
      request.onblocked = () => {
         console.log('blocked', request); reject(request) 
        }
      request.onupgradeneeded = () => {
        console.log("upgrading db")

        this.db = request.result;
        /* in the event there's a db with no stores, you sometimes get an error */
        var hasJobs = false;
        try { hasJobs = this.db.objectStoreNames.contains('jobs') } catch (e) { hasJobs = false; console.log('failed to read Object Store Jobs') }
        if (!hasJobs) {
          console.log('adding jobs store');
          let objectStore: IDBObjectStore = this.db.createObjectStore('jobs', { keyPath: 'jobID' });
          objectStore.createIndex('pMNumber', 'pMNumber');
          objectStore.createIndex('updatedDate', 'updatedDate');
          objectStore.createIndex('notificationNumber', 'notificationNumber');
          objectStore.createIndex('workDescription', 'workDescription');
        }

        var hasTags = false;
        try { hasTags = this.db.objectStoreNames.contains('tags') } catch (e) { hasTags = false; console.log('failed to read Object Store Tags') }
        if (!hasTags) {
          console.log('adding tags store');
          let objectStore: IDBObjectStore = this.db.createObjectStore('tags', { keyPath: 'jobID' });
          objectStore.createIndex('pMNumber', 'pMNumber');
          objectStore.createIndex('notificationNumber', 'notificationNumber');
          objectStore.createIndex('updatedDate', 'updatedDate');
        }

        if (!this.db.objectStoreNames.contains('foremen')) {
          let objectStore = this.db.createObjectStore('foremen', { keyPath: 'foremenID' });
        }
        if (!this.db.objectStoreNames.contains('photoUpload')) {
          let objectStore = this.db.createObjectStore('photoUpload', { keyPath: 'photoUploadID' });
        }
        this.db = request.result;
        resolve(request);
      }
    });
  }

  async deleteDatabase() {
    try { this.db.close() } catch (e) { console.log('error closing db on delete') }
    var request = indexedDB.deleteDatabase(this.databaseName);
    return new Promise(async (resolve, reject) => {
      request.onsuccess = () => {
        console.log("successfully deleted the database")
        this.db = request.result;
        resolve(request.result);
      }
      request.onerror = () => {
        console.log("shit. it didn't delete", request.error)
        reject(request.error);
      }
    })
  }

  simpleDelete(table, key) {
    var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
    var request = objectStore.count(key);
    
    request.onsuccess = () => {
      if (request.result === 0) {
         return new Promise((resolve, reject) => { 
           resolve('id not found') }) 
          } else {


        let deleteRequest = objectStore.delete(key);
        return new Promise((resolve, reject) => {
          deleteRequest.onsuccess = (retval) => {
            console.log('delete successful', retval)
            resolve(retval);
          }
          deleteRequest.onerror = (retval) => {
            console.log('delete failed', retval)
            reject(retval);
          }
        })
      }
    }
    request.onerror = () => { return new Promise((resolve, reject) => { resolve('id not found') }) }

  }


  async delete(table: string, key: number): Promise<any> {
    //this.db = await this.initializeDB();

    var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
    var request = objectStore.get(key);
    return new Promise((resolve, reject) => {
      request.onsuccess = (retval) => {
        let record = retval.target.result;
        console.log('got a record', record)
        if (record) {
          let deleteRequest = objectStore.delete(key);
          deleteRequest.onsuccess = (retval) => {
            console.log('delete successful', retval)
            resolve(retval);
          }
        } else {

          request.onerror = (retval) => {
            let record = retval.target.result;
            if (record) {
              let deleteRequest = objectStore.delete(key);
              deleteRequest.onerror = (retval) => {
                console.log('delete failed', retval)
                reject(retval);
              }
            }
          }
        }
      }
    });
  }


  async updateStatus(direction: string = 'first'): Promise<Date[]> {
    direction = (direction === 'first') ? 'prev' : 'next'
    let table = 'jobs';
    var transaction = this.db.transaction(table, "readonly");
    var objectStore = transaction.objectStore(table);
    var request = objectStore.index('updatedDate').openCursor(null, direction); // or prev 
    let res = [];
    return new Promise((resolve, reject) => {
      request.onsuccess = (event) => {

        var cursor = event.target.result;
        if (cursor) {
          if (res.length < 1) {
            res.push(cursor.value.updatedDate);
            cursor.continue();
          } else {
            cursor.advance(1000000000);
          }
        } else {

          resolve(res);
        }

      }
    });

  }


  async deleteByUpdateDate(table: string, beginDate: Date, endDate: Date, messageBoxObject = null) {
    // this.db = await this.initializeDB();
    let beginTime = beginDate.getTime();
    let endTime = endDate.getTime();
    var transaction = this.db.transaction(table, "readwrite");
    var objectStore = transaction.objectStore(table);
    var request = objectStore.openCursor();
    request.onsuccess = function (event) {
      var cursor = event.target.result;
      if (cursor) {
        // cursor.value contains the current record being iterated through
        // this is where you'd do something with the result
        let ud = new Date(cursor.value.updatedDate).getTime();
        if (ud > beginTime && ud < endTime) {
          if (messageBoxObject) {
            messageBoxObject.comment = 'clearing job ' + cursor.value.jobID
          }
          console.log('deleting local copy of job ', cursor.value.jobID);
          cursor.delete();
        }

        cursor.continue();
      } else {
        // no more results
      }
    };
  }

  async deleteRange(table: string, start: number, end: number): Promise<any> {
    if(!this.db) this.db = await this.initializeDB();
    var keyRangeValue = IDBKeyRange.bound(start, end);

    var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
    var request = objectStore.delete(keyRangeValue);
    return new Promise((resolve, reject) => {
      request.onsuccess = (retval) => {
        console.log('deleted range', retval)
        resolve(retval);

      }
    });
  }

  async select(table: string, key: number): Promise<any> {
    this.db = await this.initializeDB();

    var objectStore = this.db.transaction(table).objectStore(table);
    var request = objectStore.get(key);
    return new Promise((resolve, reject) => {
      request.onsuccess = (retval) => {
        let record = retval.target.result;
        resolve(record);
      }
    });
  }

 
  async list(table: string): Promise<any> {
    //  this.db = await this.initializeDB();
    console.log('listing table')
    
    var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
    var request = objectStore.getAll();
    return new Promise((resolve, reject) => {
      request.onsuccess = (retval) => {
        let record = retval.target.result;
        resolve(record);
      }
    });
  }

  async listTags(table: string): Promise<any> {
    console.log('listing table => ', table)
    
    var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
    // this request is not working
    //var request = objectStore.index('workDescription').get(['CCM'])
    var request = objectStore.getAll();
    
    return new Promise((resolve, reject) => {
      request.onsuccess = (retval) => {
        let record = retval.target.result;
        resolve(record);
      }
    });
  }

  async update(table: string, key: number, data: any): Promise<any> {
    if(!this.db) this.db = await this.initializeDB();

    var tx = await this.db.transaction(table, 'readwrite');
    var objectStore = tx.objectStore(table);
    var request = objectStore.get(key);
    return new Promise(async (resolve, reject) => {
      request.onsuccess = async (retval) => {
        let record = retval.target.result;
        if (!record) {
          let insertedRec = await this.insert(table, data);
          resolve(insertedRec);
        }

        let updateRequest = objectStore.put(data);
        updateRequest.onsuccess = () => { resolve(record); }
        updateRequest.onerror = (e) => {
          console.log('Error doing update into IDB', e.target.error);
          reject(e.target.error);
        }
      }
    });
  }

  async insert(table: string, data: any): Promise<any> {
    this.db = await this.initializeDB();

    var objectStore = this.db.transaction(table, 'readwrite').objectStore(table);
    var request = objectStore.add(data);
    return new Promise((resolve, reject) => {
      request.onsuccess = (retval) => { resolve(retval.result); }
      request.onerror = (e) => { reject(e.target.error); }

    });
  }

  async insertBulk(table: string, rows, callback) {
    let startTime = new Date();
    // this.db = await this.initializeDB();
    const tx = this.db.transaction(table, 'readwrite');

    rows.forEach(row => {
      tx.objectStore(table).delete(row.jobID);
      let request = tx.objectStore(table).add(row);
    })

    tx.onerror = function (event) {
      console.log('error', event)
      callback('error', event)
    }

    tx.oncomplete = function (event) {
      let endTime = new Date();
      let dif = endTime.getTime() - startTime.getTime();
      dif = dif / 1000;
      console.log('IndexedDB Insert Process ran for ' + dif + ' Seconds for ' + rows.length + ' records')
      callback('complete', event);
    }
    // tx.onsuccess = function (event) {
    //   // report the success of our request
    //   console.log('success', event)
    //   callback('success', event);
    // };


  }

  async updateBulk(table: string, rows, callback) {
    let startTime = new Date();
    let status = { recordCount: 0, success: 0, fail: 0 }
    //    this.db = await this.initializeDB();
    //   this.db = await this.initializeDB().then(obj => { console.log(obj); }, err => console.log(err));

    const tx = this.db.transaction(table, 'readwrite');
    const tbl = tx.objectStore(table);


    rows.forEach(row => {
      status.recordCount++;
      let req = tbl.get(row.jobID);
      req.onsuccess = () => { tbl.put(row); status.success++; }
      req.onerror = (err) => { status.fail++; console.log('error', err) }
    })

    tx.oncomplete = (event) => {
      let endTime = new Date();
      let dif = endTime.getTime() - startTime.getTime();
      dif = dif / 1000;
      console.log('IndexedDB Update Process ran for ' + dif + ' Seconds for ' + rows.length + ' records')
      console.log(status)
      callback(event);
    }
  }


  async clearData(table:string) {
    // open a read/write db transaction, ready for clearing the data
    const transaction = this.db.transaction([table], "readwrite");
  
    // report on the success of the transaction completing, when everything is done
    transaction.oncomplete = (event) => {
      console.log('Jobs Cleared');
    };
  
    transaction.onerror = (event) => {
      console.warn('ERROR CLEARING JOBS TABLE',transaction.error);
     };
  
    // create an object store on the transaction
    const objectStore = transaction.objectStore(table);
  
    // Make a request to clear all the data out of the object store
    const objectStoreRequest = objectStore.clear();
  
    objectStoreRequest.onsuccess = (event) => {
      // report the success of our request
      console.log('Successfully Cleared Jobs Table');
    };
  }
}
