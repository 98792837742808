import { Injectable } from '@angular/core';

@Injectable()
export class TableMap {

    private fields = new Map<string, string>();
    constructor() {
        this.fields.set("jobid", "Job");
    this.fields.set("jobname", "Job");
    this.fields.set("status", "Job");
    this.fields.set("createdbyid", "Job");
    this.fields.set("createddate", "Job");
    this.fields.set("updatedbyid", "Job");
    this.fields.set("updateddate", "Job");
    this.fields.set("jobnumber", "Job");
    this.fields.set("statuscode", "Job");
    this.fields.set("projectid", "Job");
    this.fields.set("region", "Job");
    this.fields.set("jobcode", "Job");
    this.fields.set("address1", "Job");
    this.fields.set("city", "Job");
    this.fields.set("estimatedenddate", "Job");
    this.fields.set("enddate", "Job");
    this.fields.set("duedate", "Job");
    this.fields.set("googlefileurl", "Job");
    this.fields.set("state", "Job");
    this.fields.set("postcode", "Job");
    this.fields.set("department", "Job");
    this.fields.set("changeorderamount", "Job");
    this.fields.set("citynotifyrequired", "Job");
    this.fields.set("comments", "Job");
    this.fields.set("commitdate", "Job");
    this.fields.set("contractvalue", "Job");
    this.fields.set("copsubmitteddate", "Job");
    this.fields.set("trafficcontrolcost", "Job");
    this.fields.set("crewforemanid", "Job");
    this.fields.set("customerid", "Job");
    this.fields.set("customername", "Job");
    this.fields.set("customercontact", "Job");
    this.fields.set("customercontactphone", "Job");
    this.fields.set("electricalpermitno", "Job");
    this.fields.set("encroachmentpermitno", "Job");
    this.fields.set("form2doc", "Job");
    this.fields.set("form48doc", "Job");
    this.fields.set("creategooglecalendarlink", "Job");
    this.fields.set("googlemap", "Job");
    this.fields.set("invoiceid", "Job");
    this.fields.set("inspectiongroup", "Job");
    this.fields.set("pentajobno", "Job");
    this.fields.set("pentadate", "Job");
    this.fields.set("packagehardcopycomplete", "Job");
    this.fields.set("nodegeneralcontractor", "Job");
    this.fields.set("pgnefieldengineer", "Job");
    this.fields.set("redundantdontuse", "Job");
    this.fields.set("powro", "Job");
    this.fields.set("primarycustomer", "Job");
    this.fields.set("quoteid", "Job");
    this.fields.set("siteid", "Job");
    this.fields.set("forecaststartdate", "Job");
    this.fields.set("actualstartdate", "Job");
    this.fields.set("trafficcontrolcomment", "Job");
    this.fields.set("workdescription", "Job");
    this.fields.set("matcode", "Job");
    this.fields.set("metric1", "Job");
    this.fields.set("apsrequired", "Job");
    this.fields.set("restorationcost", "Job");
    this.fields.set("trafficcontrolplansdoc", "Job");
    this.fields.set("ownerid", "Job");
    this.fields.set("generalforemanid", "Job");

 
    this.fields.set("additionalcost", "Bid");
    this.fields.set("awarddate", "Bid");
    this.fields.set("baseelectricamount", "Bid");
    this.fields.set("bundlename", "Bid");
    this.fields.set("duedate", "Bid");
    this.fields.set("notificationnumber", "Bid");
    this.fields.set("pgneowner", "Bid");
    this.fields.set("sitecode", "Bid");
    this.fields.set("jpintent", "Bid");
    this.fields.set("googledrivefolder", "Bid");
    this.fields.set("ownerid", "Bid");
    this.fields.set("bidownerid", "Bid");
    
    this.fields.set("price", "Bid");
    this.fields.set("premiumhours", "Bid");
    this.fields.set("requestdate", "Bid");
    this.fields.set("concreterestorationamount", "Bid");
    this.fields.set("straighthours", "Bid");
    this.fields.set("status", "Bid");
    this.fields.set("submitteddate", "Bid");
    this.fields.set("trafficcontrolamount", "Bid");
    this.fields.set("workingdays", "Bid"); 
    }
    getMap(columnName: string): string {
        columnName = columnName.toLowerCase();
        if (this.fields.has(columnName)) {
            return this.fields.get(columnName);
        } else {
            return "";
        }
    }
}
