import { Component } from '@angular/core';
 
import { MenuComponent, ContextMenuService, MenuPackage } from '@ctrl/ngx-rightclick';
 
@Component({
  selector: 'file-menu',
  // add your menu html
  template: `
    <style>
     
    .menu-item {
        cursor:pointer;
        color:black;
        text-decoration:underline;
   }
   .menu-item:hover {
        text-decoration:none;
        
   }
   .menu-box{
    background-color:white;cursor:pointer;width:100px;padding:20px;border:lightgray solid 1px;
   }
    </style>
  <div class="menu-box"><a class="menu-item" (click)="handleClick()">Delete</a></div>`,
})
export class FileMenuComponent extends MenuComponent {
  // this module does not have animations, set lazy false
  lazy = false;
 
  file;
  constructor(
    public menuPackage: MenuPackage,
    public contextMenuService: ContextMenuService,
  ) {
    super(menuPackage, contextMenuService);
    // grab any required menu context passed via menuContext input
    this.file = menuPackage.context;
    console.log(menuPackage.context)
  }
 
  handleClick() {
    // IMPORTANT! tell the menu to close, anything passed in here is given to (menuAction)
    this.contextMenuService.closeAll(this.file);
  }
}