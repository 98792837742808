import { Injectable } from '@angular/core';
import { min } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../../../environments/environment";
import { AuthService } from '../auth/auth.service';
import { SiteReviewService } from '../site-review.service';

interface IMenuItem {
  type: string,       // Possible values: link/dropDown/icon/separator/extLink
  name?: string,      // Used as display text for item and title for separator type
  state?: string,     // Router state
  icon?: string,      // Item icon name
  tooltip?: string,   // Tooltip text 
  disabled?: boolean, // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]  // Dropdown items
}
interface IChildItem {
  name: string,        // Display text
  state: string        // Router state
  queryParams?: any // query parameters
}

@Injectable()
export class NavigationService {

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = 'Frequently Accessed';
  // sets iconMenu as default;
  private menuItems = new BehaviorSubject<IMenuItem[]>([]);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  userData: any;
  filteredItems = [];

  constructor(
    private auth: AuthService,
    private db: SiteReviewService,
  ) {

    this.getUserGroups();
    this.buildMenu();

  }

  getUserGroups() {
    const curUserData = this.auth.currentUser;

    if (!curUserData.groups) {
      console.log('groups not working!!')
      return;
    } else {
      this.userData = curUserData.groups;

      if (curUserData.userLevel.rank != 10) {
        this.defaultMenu = this.defaultMenu.filter(obj => obj.name != 'Administrative')
        this.defaultMenu = this.defaultMenu.filter(obj => obj.name != 'Development') 
        this.defaultMenu = this.defaultMenu.filter(obj => obj.name != 'Crews') 
   

      }

 
      
      this.filterMenu(curUserData.groups,'fleet','Fleet')
      this.filterMenu(curUserData.groups,'fleet','Tooling')
      this.filterMenu(curUserData.groups,'time','Time')
 
      

    }

  }
  filterMenu(groups:string[],groupName:string, menuName:string){
    let devs = groups.filter(g => g.toLowerCase() === groupName.toLowerCase());     
    
    if (devs.length == 0) {
      this.defaultMenu = this.defaultMenu.filter(obj => obj.name.toLowerCase() != menuName.toLowerCase())      
    }
     
  }

  buildMenu() {

    this.db.getDashboardData().subscribe(res => {
      console.log("DASHBOARD DATA NAV SERVICE=> ", res);
      let dashboardName = 'primary'
      let outstandingMenuItems = this.appendMenuItemsToDefault(dashboardName, res);
      this.appendMenuItemsBeyondDefault(dashboardName, outstandingMenuItems);
      this.menuItems.next(this.defaultMenu)
    })

  }

  appendMenuItemsToDefault(dashboardName, customMenuItems) {
    let outstandingMenuItems = [];

    //mark them all as not-added
    customMenuItems.forEach(item => { item.added = false; });


    /* 
    cycle through the existing menu items and append new items to
    matching menus.
    */
    this.defaultMenu.forEach(m => {
      let items = customMenuItems.filter(res => res.category === m.name);
      let subs = [];
      items.forEach(item => {
        let state = '';
        if(item.uri.indexOf('http')!=0){
          state=item.uri;
        }else{
          state = 'dashboard/link/' + dashboardName + '/' + item.title
        }
        let si = {
          name: item.title,
          state: state,
        }
        item.added = true;
        subs.push(si);
      });
      m.sub.push.apply(m.sub, subs)
    });


    outstandingMenuItems = customMenuItems.filter(res => !res.added)
    console.log("Remaining Items", outstandingMenuItems);

    this.filteredItems = outstandingMenuItems;
    console.log("filteredItems => ", this.filteredItems);

    let filteredData = [];

    this.filteredItems.forEach(item => {
      let GA = item.groupAccess.replace(/ /g, '');
      let parsedGroup = GA.split(',');
      parsedGroup.forEach(obj => obj = obj.trim());
      parsedGroup.forEach(obj => obj = obj.replace(/ /g, ''));
      this.userData.forEach(role => {
        let canViewSection = parsedGroup.some(ga => {
          ga = ga.replace(/ /g, '');
          ga = ga.replace('Foreman', 'Foremen');
          role = role.replace(/ /g, '');
          role = role.replace('Foreman', 'Foremen');
          //console.log(ga, role, ga === role)
          return ga === role;

        });
        if (canViewSection) {
          filteredData.push(item);
        } else {
          return;
        }
      })
    });

    outstandingMenuItems = [...new Set(filteredData)];

    console.log("FILTERED Remaining Items", outstandingMenuItems)

    return outstandingMenuItems;
  }

  appendMenuItemsBeyondDefault(dashboardName, outstandingMenuItems) {
    var categories = [];
    outstandingMenuItems.forEach(mi => {
      let exists = categories.filter(cats => cats === mi.category);
      if (exists.length === 0) categories.push(mi.category)
    });
    console.log('New Categories', categories)
    categories.forEach(cat => {
      let menuItem: IMenuItem = { "type": "dropDown", "name": cat, "tooltip": "tool tip here", "icon": "link", "sub": [] }

      let items = outstandingMenuItems.filter(res => res.category === cat);
      items.forEach(item => {
        let state = '';
        if(item.uri.indexOf('http')!=0){
          state=item.uri;
        }else{
          state = 'dashboard/link/' + dashboardName + '/' + item.title
        }
        let si;
        si = {
          name: item.title,
          state: state,
        }
        menuItem.sub.push(si);
      });
      console.log('Category Added ', menuItem)
      this.defaultMenu.push(menuItem);
    })



  }

  defaultMenu: IMenuItem[] = [
   
    {
      name: 'Operations',
      type: 'dropDown',
      tooltip: 'Operations',
      icon: 'assignment',
      sub: [
        // { name: 'Maps', state: 'reviews/maps' },
        { name: 'Active Jobs', state: 'reviews/jobs' },
        { name: 'Archived Jobs', state: 'reviews/archived-jobs' },
        { name: 'P&C Tracker', state: 'reviews/pc-tracker' },
        { name: 'Cost/Forecast Est.', state: 'finance/cost-estimator' },
        { name: 'Subcontractor Invoices', state: 'reviews/subcontractor-reports' },
        { name: 'Customers', state: 'customers' },
        { name: 'Imports', state: 'reviews/job-imports' },
        { name: 'Equipment List', state: 'reviews/equipment-list-operations' },
      

        // { name: 'Add New Job', state: 'reviews/jobs/new' },
        // { name: 'Locations', state: 'reviews/sites' },
        // { name: 'Customers', state: 'reviews/customers' },
        // { name: 'Reports', state: 'reviews/reports' },
        // { name: 'Report Templates', state: 'reviews/templates' },       
        // { name: 'Import New Jobs', state: 'reviews/package-imports' },
        // { name: 'Import Closeouts', state: 'reviews/closeout-imports' },
        
        /*
        LBS
        https://docs.google.com/forms/d/e/1FAIpQLScqBgxWO-q9scDSdOnUFZC31HfMkFti_SVtRcbpo6G4HowcAA/viewform
        */
      ]




      //   // Older menu layout
      // [
      //   // { name: 'Maps', state: 'reviews/maps' },
      //   { name: 'All Jobs', state: 'reviews/jobs' },
      //   // { name: 'Add New Job', state: 'reviews/jobs/new' },
      //   // { name: 'Locations', state: 'reviews/sites' },
      //   // { name: 'Customers', state: 'reviews/customers' },
      //   { name: 'Customers', state: 'customers' },
      //   { name: 'Cost/Forecast Est.', state: 'finance/cost-estimator' },
      //   // { name: 'Reports', state: 'reviews/reports' },
      //   // { name: 'Report Templates', state: 'reviews/templates' },       
      //   { name: 'Import New Jobs', state: 'reviews/package-imports' },
      //   { name: 'Import Closeouts', state: 'reviews/closeout-imports' },
      //   { name: 'Archives', state: 'reviews/archived-jobs' },
      //   /*
      //   LBS
      //   https://docs.google.com/forms/d/e/1FAIpQLScqBgxWO-q9scDSdOnUFZC31HfMkFti_SVtRcbpo6G4HowcAA/viewform
      //   */
      // ]
    },
    {name: 'Time',
    type:'dropDown',
    tooltip: 'TimeSheet',
    icon: 'business',
    sub: [
      { name: 'Sheet Approval', state:'reviews/timesheets-admin-report'},
      { name: 'Card Review', state: 'reviews/timesheets' },
      { name: 'Batch Exports', state: 'reviews/timesheets' },

    ]

  }
  ,
    // {
    //   name: 'Maps',
    //   type: 'dropDown',
    //   tooltip: 'Setup',
    //   icon: 'map',
    //   // state: 'reviews',
    //   sub: [
    //     { name: 'CCM Sonoma', state: 'reviews/maps/ccm-sonoma' },
    //     { name: 'CCM Grid', state: 'reviews/maps/ccm-map-grid' }
    //     // { name: 'My Groups', state: 'sites/map-regions' },
    //     // { name: 'My Crews', state: 'sites/map-regions' }
    //   ]
    // },
    // {
    //   name: 'CCM',
    //   type: 'dropDown',
    //   tooltip: 'CCM',
    //   icon: 'business',
    //   sub: [
    //     { name: 'CCM Master', state: 'reviews/ccm-master' },
    //     // { name: 'old CCM Tags', state: 'reviews/upload' },
    //     { name: 'CCM Tags', state: 'reviews/ccm-tags' },
    //   ]
    // },
    // { 
    //   name: 'Finance',
    //   type: 'dropDown',
    //   tooltip: 'Finance',
    //   icon: 'account_balance',
    //   sub: [
    //     { name: 'Cost Estimator', state: 'finance/cost-estimator' },
    //    ]
    // },
    {
      name: 'Fleet',
      type: 'dropDown',
      tooltip: 'Fleet',
      icon: 'fence',
      sub: [

        { name: 'Equipment List', state: 'fleet/vehicles' },
        { name: 'Repair Orders', state: 'fleet/repair-orders' },
        // { name: 'Vehicle Inspections', state: 'fleet/inspections' },
        { name: 'Equipment Types', state: 'fleet/equipment-types' },
        { name: 'PM List', state: 'fleet/pm-list' }
      ]
    },
    {
      name: 'Tooling',
      type: 'dropDown',
      tooltip: 'Fleet',
      icon: 'fence',
      sub: [


        // { name: 'Repair Orders', state: 'fleet/repair-orders' },
        // { name: 'Vehicle Inspections', state: 'fleet/inspections' },
        // { name: 'Equipment Types', state: 'fleet/equipment-types' },
        // { name: 'PM List', state: 'fleet/pm-list' }
      ]
    },
    {
      name: 'Administrative',
      type: 'dropDown',
      tooltip: 'Administration',
      icon: 'security',
      /* state: 'jobs', */
      sub: [
        { name: 'Users', state: 'admin/users' },
        { name: 'Groups', state: 'admin/teams' },
        { name: 'Calendars', state: 'admin/calendars' },
        { name: 'Timesheets', state: 'reviews/timesheets' },
        { name: 'Timesheets Graph Report', state: 'reviews/timesheets-graph-report' },
        { name: 'Timesheets Admin Report', state: 'reviews/timesheets-admin-report' },
        { name: 'Dashboards', state: 'admin/dashboards' },
        {name: 'Job Types', state: 'admin/job-types'},

        // { name: 'Regional Filters', state: 'reviews/maps' },
        { name: 'Report Templates', state: 'reviews/templates' },
        { name: 'Project Based Work', state: 'admin/project-based-work' },
        { name: 'Penta', state: 'reviews/dynagrid' },
        { name: 'Upload PDF Files', state: 'admin/uploads' },
        { name: 'Settings', state: 'admin/settings' },
        // {name: 'Domains', state: 'admin/users'},
        // {name: 'PnL', state: 'loader'},
        // {name: 'Weekly Wip', state: 'loader'},
        // {name: 'Weekly Job Data', state: 'loader'},
        // {name: 'Overhead', state: 'loader'},
      ]
    },

    {
      name: 'Crews',
      type: 'dropDown',
      tooltip: 'New Items',
      icon: 'supervisor_account',
      /* state: 'jobs', */
      sub: [
        { name: 'Crew Locator', state: 'crews/crews' },        
        { name: 'Crew Regions', state: 'reviews/maps' },
        // { name: 'Equipment', state: 'crews/crew-equipment-manager' },
         { name: 'Members (dev)', state: 'crews/crew-member-manager' },
        //  { name: 'schedules (dev)', state: 'reviews/schedules' },
      ]
    },
    {
      name: 'Development',
      type: 'dropDown',
      tooltip: 'New Items',
      icon: 'engineering',
      /* state: 'jobs', */
      sub: [
        { name: 'Dashboard', state: 'dashboard' },
        { name: 'ROs', state: 'fleet/ros' },
        { name: 'LBS Entry', state: 'fleet/lbs-entry' },
        { name: 'Job Reports', state: 'reviews/jobreports' },

        { name: 'Civil Work Needed', state: 'reviews/civil-work-needed' },
        { name: 'USA Tickets', state: 'reviews/usa-tickets' },



        // { name: 'submission', state: 'reviews/jobs/7433/submission/1' },
        // { name: 'charts', state: 'dashboard/charts' },
        // { name: 'invoices', state: 'reviews/invoices' },
        // { name: 'new invoice', state: 'reviews/invoices/new' },
        // { name: 'invoice 1', state: 'reviews/invoices/1' },

        // { name: 'new schedule', state: 'reviews/schedules/new' },
        // { name: 'schedule 123', state: 'reviews/schedules/123' },

        // { name: 'clearances', state: 'reviews/clearances' },
        // { name: 'new clearance', state: 'reviews/clearances/new' },
        // { name: 'clearance 123', state: 'reviews/clearances/123' },


        //  {name: 'Job SU', state: 'ops/jobs/new' },
        // {name: 'Domains', state: 'admin/users'},
        //  {name: 'PnL', state: 'loader'},
        // {name: 'Weekly Wip', state: 'loader'},
        // {name: 'Weekly Job Data', state: 'loader'},
        //  {name: 'Overhead', state: 'loader'},
      ]
    },
    // {
    //   name: 'Crews',
    //   type: 'dropDown',
    //   tooltip: 'Crews',
    //   icon: 'fence',
    //   /* state: 'crews', */
    //   sub: [
    //     { name: 'Crew Locator', state: 'crews/crews' },
    //     { name: 'Equipment', state: 'crews/crew-equipment-manager' },
    //     { name: 'Members', state: 'crews/crew-member-manager' }

    //   ]
    // },
    // {
    //   name: 'Operations',
    //   type: 'dropDown',
    //   tooltip: 'Operations',
    //   icon: 'filter_none',
    //   state: 'ops',
    //   sub: [
    //     {name: 'Op Center', state: ''},
    //     {name: 'Quotes', state: 'quotes'},
    //     {name: 'Quotes ag grid', state: 'quotes/ag-grid'},
    //     {name: 'Jobs', state: 'jobs'},             
    //     {name: 'IT Tracker', state: 'itr'}             
    //   ]
    // }
    // ,
    // {
    //   name: 'Client Center',
    //   type: 'dropDown',
    //   tooltip: 'Client Center',
    //   icon: 'filter_none',
    //   state: 'clients',
    //   sub: [
    //     {name: 'Trackers', state: 'trackers'}
    //   ]
    // }
    // , {
    //   name: 'Customers',
    //   type: 'link',
    //   tooltip: 'Customers',
    //   icon: 'filter_none',
    //   state: 'customers'
    // },

    // {
    //   name: 'Ops Tools',
    //   type: 'dropDown',
    //   tooltip: 'Ops Tools',
    //   icon: 'filter_none',
    //   state: 'ops',
    //   sub: [

    //     {name: 'The Q', state: 'q'},               
    //   ]
    // }, 
    // {
    //   name: 'Company Info',
    //   type: 'dropDown',
    //   tooltip: 'Company Info',
    //   icon: 'filter_none',
    //   state: 'info',
    //   sub: [
    //     {name: 'Forms', state: 'forms'},
    //     {name: 'Directory', state: 'directory'},               
    //   ]
    // }, 

  ]




}