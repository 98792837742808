<style>
  .admin-item {
    padding: 2px;
    border: solid gray 1px;
    margin-bottom: 5px;
    width: 95%;
  }
  .icon {
    font-size: 10px;
}

</style>
<mat-nav-list class="" role="list">
  <!--==== Side menu items ====-->

  <mat-accordion>
    <mat-expansion-panel *ngFor="let item of menuItems">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon  [inline]="true">{{item.icon}}</mat-icon>&nbsp;
          <span>{{item.name}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list class="sub-menu" role="list" *ngIf="item.type === 'dropDown'">
        <mat-list-item  style="font-size: 10pt;padding:0;margin:0"  routerLinkActive="selected" *ngFor="let subItem of item.sub">
          <a routerLink="{{item.state ? '/'+item.state : ''}}/{{subItem.state}}" [queryParams]="subItem.queryParams">{{subItem.name}}</a>
        </mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>
 


    <!--  CUSTOM ADDITIONS -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon  [inline]="true">sync</mat-icon>
          <span>&nbsp;Server Status</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul style="font-size: 8pt;color:gray;margin-left:0;padding-left:0; list-style-type: none;">
        <li> <b>data server</b>
          <div class="admin-item">
            {{env.siteReviewURL}}<br>
            {{dataServerStatus.Database}}<br>
            {{dataServerStatus.DBStatus}}<br>
          </div>
        </li>
        <li><b>pdf services</b>
          <div class="admin-item">
            {{env.serverURL}}<br>
            {{apiServerStatus.InitialCatalog}}<br>
            {{apiServerStatus.DBStatus}}<br>
          </div>
        </li>
        <li><b>Session Status</b>
          <div class="admin-item">
            <div #sessionTimeout></div>
            <div>{{sessionTimeDisplay}} mins left to log out</div>
          </div>
        </li>
        <li><b>Release Info</b>
          <div class="admin-item">
            <div >Compile Time: {{relInfo.compileTime}}</div>
            <div >Version Tag: {{relInfo.lastTag}}</div>
            <div >Git Branch: {{relInfo.branch}}</div>
            <div >Last Commit: {{relInfo.lastCommit}}</div>
            <div >Build Type: {{relInfo.buildType}}</div>
          </div>
        </li>
      </ul>
    </mat-expansion-panel>
  
  </mat-accordion>

 
</mat-nav-list>