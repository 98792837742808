import { Injectable } from '@angular/core';

@Injectable()
export class SmartSheetMap {

    private fields = new Map<string, string>();
    private sheetFields = new Map<string, string>();
    constructor() {
        this.fields.set("jobName", "Job Name")


        this.fields.set("aPSRequired", "APS Required")
        this.fields.set("bidAdditionalCost", "Bid Additional Costs")
        this.fields.set("bidAwardDate", "Bid Award Date")
        this.fields.set("bidBaseElectric", "Bid Base Electric")
        this.fields.set("bidBundleName", "Bid Bundle Name")
        this.fields.set("bidDaysSinceRequest", "Bid Days Since Request")
        this.fields.set("bidDaysUntilDue", "Bid Days Until Due")
        this.fields.set("bidDueDate", "Bid Due Date")
        this.fields.set("bidGDriveFolder", "Bid G-Dive Folder")
        this.fields.set("bidOwnerID", "Bid Owner")
        this.fields.set("bidPrice", "Bid Price")
        this.fields.set("bidPThrs", "Bid PT Hrs")
        this.fields.set("bidRequestDate", "Bid Request Date")
        this.fields.set("bidRestoration", "Bid Restoration")
        this.fields.set("bidSThrs", "Bid ST Hrs")
        this.fields.set("bidStatus", "Bid Status")
        this.fields.set("bidSubmittedDate", "Bid Submitted Date")
        this.fields.set("bidTotalDaysToBid", "Bid Total Days to Bid")
        this.fields.set("bidTrafficControl", "Bid Traffic Control")
        
        this.fields.set("changeOrders", "Change Orders")
        this.fields.set("cityNotification", "City Notification")
        this.fields.set("comments", "Comments")
        this.fields.set("commitDate", "Commit date")
        this.fields.set("contractValue", "Contract Value")
        this.fields.set("cOPSubmitted", "COP Submitted")
        this.fields.set("costRestoration", "Cost Restoration")
        this.fields.set("costTrafficControl", "Cost Traffic Control")
        this.fields.set("crewForeman", "Crew Foreman")
        this.fields.set("customer", "Customer")
        this.fields.set("customerContact", "Customer Contact")
        this.fields.set("customerContactPhone", "Customer Contact Phone")
        this.fields.set("differenceRestoration", "Difference Restoration")
        this.fields.set("differenceTrafficControl", "Difference Traffic Control")
        this.fields.set("docsElectricalPermit", "Docs Electrical Permit")
        this.fields.set("docsEncroachmentPermit", "Docs Encroachment Permit")
        this.fields.set("docsForm2", "Docs Form 2")
        this.fields.set("docsForm48", "Docs Form 48")
        this.fields.set("docsTrafficControlPlans", "Docs Traffic Control Plans")
        this.fields.set("endDateActual", "End Date Actual")
        this.fields.set("endDateForecast", "End Date Forecast")
        
        this.fields.set("gMaps", "G-Maps")
        this.fields.set("invoiceNumber", "General Foreman")
        this.fields.set("invoiceNumber", "Invoice #")
        this.fields.set("invoiceDate", "Invoice Date")
        this.fields.set("inspectionGroup", "Inspection Group")
        this.fields.set("jobPercentComplete", "Job % Complete")
        this.fields.set("jobAddress", "Job Address")
        this.fields.set("jobCity", "Job City")
        this.fields.set("jobGDriveFolder", "Job G-Drive Folder")
         this.fields.set("jobNumber", "Job Number")
        this.fields.set("jobNumberPenta", "Job Number Penta")
        this.fields.set("jobPackageHardCopyComplete", "Job Package Hard Copy Complete")
        this.fields.set("jobState", "Job State")
        this.fields.set("jobStatus", "JP Intent")
        this.fields.set("mATCode", "MAT Code")
        this.fields.set("nodeGC", "Node GC")
        this.fields.set("pentaDate", "PENTA date")
        this.fields.set("pGEFieldEngineer", "PG&E Field Engineer")
        this.fields.set("pGERegion", "PG&E Region")
        this.fields.set("pinnacleDepartment", "Pinnacle Department")
        this.fields.set("pMNumber", "PM #")
        this.fields.set("pOWRO", "PO/WRO")
        this.fields.set("primaryCustomer", "Primary Customer")
        this.fields.set("quoteID", "Quote ID")
        this.fields.set("siteID", "Site ID")
        this.fields.set("startDateActual", "Start Date Actual")
        this.fields.set("startDateForecast", "Start Date Forecast")
        this.fields.set("totalInvoiced", "Total Invoiced")
        this.fields.set("trafficControl", "Traffic Control")
        this.fields.set("workDescription", "Work Description")
        this.fields.set("metric1", "Metric1");
        this.fields.set("MATCode", "MAT Code")
        this.fields.set("State","State")


        this.fields.set("Department","Pinnacle Department")
        this.fields.set("JobNumber","PM #")
        this.fields.set("JobName","Job Name")
        this.fields.set("Address1","Job Address");
        this.fields.set("City","Job City");
        this.fields.set("State","Job State");
        this.fields.set("SiteCode","Site ID");
        this.fields.set("SubmittedDate","Bid Submitted Date");
        this.fields.set("DueDate","Bid Due Date");
        
        this.fields.set("RequestDate","Bid Request Date");
        this.fields.set("WorkDescription","Work Description")
        this.fields.set("BidOwner", "Bid Owner")
        this.fields.set("CustomerContactPhone","Customer Contact Phone")
        this.fields.set("CustomerContact","Customer Contact")
        this.fields.set("Customer","Customer")
        this.fields.set("jPIntentString","JP Intent")
        

        

        
       //this.fields.set("NotificationNumber","NotificationNumber")



        this.sheetFields.set("SmartSheets", "DB")
this.sheetFields.set("APS_Required", "aPSRequired")
this.sheetFields.set("Bid_Additional_Costs", "bidAdditionalCost")
this.sheetFields.set("Bid_Award_Date", "bidAwardDate")
this.sheetFields.set("Bid_Base_Electric", "bidBaseElectric")
this.sheetFields.set("Bid_Bundle_Name", "bidBundleName")
this.sheetFields.set("Bid_Days_Since_Request", "bidDaysSinceRequest")
this.sheetFields.set("Bid_Days_Until_Due", "bidDaysUntilDue")
this.sheetFields.set("Bid_Due_Date", "bidDueDate")
this.sheetFields.set("Bid_G_Dive_Folder", "bidGDriveFolder")
this.sheetFields.set("Bid_Owner", "bidOwnerID")
this.sheetFields.set("Bid_PT_Hrs", "bidPrice")
this.sheetFields.set("Bid_Price", "bidPThrs")
this.sheetFields.set("Bid_Request_Date", "bidRequestDate")
this.sheetFields.set("Bid_Restoration", "bidRestoration")
this.sheetFields.set("Bid_ST_Hrs", "bidSThrs")
this.sheetFields.set("Bid_Status", "bidStatus")
this.sheetFields.set("Bid_Submitted_Date", "bidSubmittedDate")
this.sheetFields.set("Bid_Total_Days_to_Bid", "bidTotalDaysToBid")
this.sheetFields.set("Bid_Traffic_Control", "bidTrafficControl")
this.sheetFields.set("CCSC_Create_Job", "NONE")
this.sheetFields.set("COP_Submitted", "NONE")
this.sheetFields.set("Change_Orders", "changeOrders")
this.sheetFields.set("City_Notification", "cityNotification")
this.sheetFields.set("Comments", "comments")
this.sheetFields.set("Commit_date", "commitDate")
this.sheetFields.set("Contract_Value", "contractValue")
this.sheetFields.set("NONE", "cOPSubmitted")
this.sheetFields.set("Cost_Restoration", "costRestoration")
this.sheetFields.set("Cost_Traffic_Control", "costTrafficControl")
this.sheetFields.set("Crew_Foreman", "crewForeman")
this.sheetFields.set("Customer", "customer")
this.sheetFields.set("Customer_Contact", "customerContact")
this.sheetFields.set("Customer_Contact_Phone", "customerContactPhone")
this.sheetFields.set("Difference_Restoration", "differenceRestoration")
this.sheetFields.set("Difference_Traffic_Control", "differenceTrafficControl")
this.sheetFields.set("Docs_Electrical_Permit", "docsElectricalPermit")
this.sheetFields.set("Docs_Encroachment_Permit", "docsEncroachmentPermit")
this.sheetFields.set("Docs_Form_2", "docsForm2")
this.sheetFields.set("Docs_Form_48", "docsForm48")
this.sheetFields.set("Docs_Traffic_Control_Plans", "docsTrafficControlPlans")
this.sheetFields.set("End_Date_Actual", "endDateActual")
this.sheetFields.set("End_Date_Forecast", "endDateForecast")
this.sheetFields.set("G_Calendar_Create_New_Event", "NONE")
this.sheetFields.set("G_Maps", "gMaps")
this.sheetFields.set("General_Foreman", "NONE")
this.sheetFields.set("ID", "NONE")
this.sheetFields.set("Inspection_Group", "NONE")
this.sheetFields.set("Invoice_Date", "invoiceDate")
this.sheetFields.set("Invoice_Number", "invoiceNumber")
this.sheetFields.set("JP_Intent", "NONE")
this.sheetFields.set("Job_Address", "jobAddress")
this.sheetFields.set("Job_City", "jobCity")
this.sheetFields.set("Job_G_Drive_Folder", "jobGDriveFolder")
this.sheetFields.set("Job_Name", "jobName")
this.sheetFields.set("Job_Number", "jobNumber")
this.sheetFields.set("Job_Number_Penta", "jobNumberPenta")
this.sheetFields.set("Job_Package_Hard_Copy_Complete", "jobPackageHardCopyComplete")
this.sheetFields.set("Job_Percent_Complete", "jobPercentComplete")
this.sheetFields.set("Job_State", "jobState")
this.sheetFields.set("MAT_Code", "mATCode")
this.sheetFields.set("Mertic2", "metric1")
this.sheetFields.set("Metric1", "NONE")
this.sheetFields.set("Metric3", "NONE")
this.sheetFields.set("Metric4", "NONE")
this.sheetFields.set("ModifiedAt", "NONE")
this.sheetFields.set("ModifiedBy", "NONE")
this.sheetFields.set("Node_GC", "nodeGC")
this.sheetFields.set("PENTA_date", "pentaDate")
this.sheetFields.set("PGE_Field_Engineer", "pGEFieldEngineer")
this.sheetFields.set("PGE_Region", "pGERegion")
this.sheetFields.set("PM_Number", "pMNumber")
this.sheetFields.set("PO_WRO", "pOWRO")
this.sheetFields.set("Pinnacle_Department", "pinnacleDepartment")
this.sheetFields.set("Primary_Customer", "primaryCustomer")
this.sheetFields.set("Quote_ID", "quoteID")
this.sheetFields.set("RowNumber", "NONE")
this.sheetFields.set("Site_ID", "siteID")
this.sheetFields.set("Start_Date_Actual", "startDateActual")
this.sheetFields.set("Start_Date_Forecast", "startDateForecast")
this.sheetFields.set("Total_Invoiced", "totalInvoiced")
this.sheetFields.set("Traffic_Control", "trafficControl")
this.sheetFields.set("Work_Description", "workDescription")

    }
    getMap(columnName: string): string {
        if (this.fields.has(columnName)) {
            return this.fields.get(columnName);
        } else {
            return "";
        }
    }
    getMapFromSheet(columnName: string): string {
        if (this.sheetFields.has(columnName)) {
            return this.sheetFields.get(columnName);
        } else {
            return "";
        }
    }
}
