import { RepairOrder } from "app/models/fleet/repair-order";
import { Vehicle } from "app/models/fleet/vehicle";
import { User } from "app/models/User";

export interface StoreState {
    vehicles: Vehicle[];
    repairOrders: RepairOrder[];
    equipmentCategories: EquipmentCategory[];
    jobTypes:JobType[];
    foreman:Foreman[];
    vendors:Vendor[];
    user:User;
    optionList:OptionList[];
    userList:User[];
    refreshObject:RefreshObject
}

/** the name of the component you want to be refreshed */
export class RefreshObject{
    componentName:string;
    refreshRequired:boolean;
}
export interface EquipmentCategory{
    equipmentTypeID:number;  //yes, I know.  this is a mess because the DB was designed around 'type', but it has changed.
    category:string;
    image?:string;
    name?:string;
    icon?:string;
    description?:string;
    vehicleCount?:number;
    status?:string;
    subcategories: [{title:string,value:string}]
}

export interface JobType{
    jobTypeID:number;
    description:string;
    code:string;
    sortOrder:number;
    defaultInvoiceNumber:string;
    status:string;
}

export interface OptionList{
  optionName:string;
  optionValue:string;
}

export interface Vendor{
    vendorID:number;
    vendorName:string;
}
export enum JobTypeActions {
    AddJobType= 'ADD_JOBTYPE',
    UpdateJobType= 'UPDATE_JOBTYPE',
    RemoveJobType = 'REMOVE_JOBTYPE',
    GetJobType = 'GET_JOBTYPE',
  }

  export enum RepairOrderActions{
    AddRepairOrder= 'ADD_REPAIRORDER',
    UpdateRepairOrder= 'UPDATE_REPAIRORDER',
    RemoveRepairOrder = 'REMOVE_REPAIRORDER',
    GetRepairOrder = 'GET_REPAIRORDER',
  }
  export enum OptionListActions{
    AddOptionList= 'ADD_OPTIONLIST',
    UpdateOptionList= 'UPDATE_OPTIONLIST',
    RemoveOptionList = 'REMOVE_OPTIONLIST',
    GetOptionList = 'GET_OPTIONLIST',
  }

export interface Foreman{
    id:string;
    text:string;
    type:string;
} 

export enum ForemanStoreActions {
  AddForeman = 'ADD_FOREMAN',
  UpdateForeman = 'UPDATE_FOREMAN',
  RemoveForeman = 'REMOVE_FOREMAN',
  GetForeman = 'GET_FOREMAN',

}
export enum UserStoreActions {
    AddUser= 'ADD_USER',
    UpdateUser= 'UPDATE_USER',
    RemoveUser = 'REMOVE_USER',
    GetUser = 'GET_USER',
  }

  export enum VendorStoreActions {
    AddUser= 'ADD_VENDOR',
    UpdateUser= 'UPDATE_VENDOR',
    RemoveUser = 'REMOVE_VENDOR',
    GetUser = 'GET_VENDOR',
  }

  export enum UserListStoreActions {
    AddUserList= 'ADD_USERLIST',
    UpdateUserList= 'UPDATE_USERLIST',
    RemoveUserList = 'REMOVE_USERLIST',
    GetUserList = 'GET_USERLIST',
  }